import { ReactElement, memo } from "react"
import useWindowSize from "../../hooks/useWindowSize"
import { InputAdornment, TextField } from "@mui/material"

type IProps = {
    type: string
    name: string
    value: string
    className: string
    fullWidth: boolean
    placeholder: string
    icon: ReactElement<any, any> | string
    style: React.CSSProperties | undefined
    onChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> | undefined
    onKeyUp: React.KeyboardEventHandler<HTMLDivElement> | undefined
}

const InputSignUp = ({ placeholder, icon, onChange, fullWidth, type, name, value, className, style, onKeyUp }: IProps) => {

    const { width } = useWindowSize()

    return (
        <>
            {value === "" 
                ? <TextField
                        required
                        type={type}
                        name={name}
                        style={style}
                        onKeyUp={onKeyUp}
                        variant="outlined"
                        onChange={onChange}
                        fullWidth={fullWidth}
                        className={className}
                        placeholder={placeholder}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment style={{ margin: '0', position: 'absolute', left: width <= 768 ? '6px' : '-5px', borderRadius: "50rem" }} position="start">
                                    {typeof(icon) === 'string' ? <img src={icon} alt="icon" height="60px" /> : <div style={{width: width <= 768 ? '40px' : '51px', height: width <= 768 ? '40px' : '51px', borderRadius: '40rem', backgroundColor: '#009FE3', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>{icon}</div>}
                                </InputAdornment>
                            ),
                            inputProps: { sx: { paddingLeft: '30px !important' } },
                            style: { paddingLeft: '20px', borderRadius: '40rem' }
                        }}
                    />
                : <TextField
                required
                type={type}
                name={name}
                value={value}
                style={style}
                onKeyUp={onKeyUp}
                variant="outlined"
                onChange={onChange}
                fullWidth={fullWidth}
                className={className}
                placeholder={placeholder}
                InputProps={{
                    startAdornment: (
                        <InputAdornment style={{ margin: '0', position: 'absolute', left: width <= 768 ? '6px' : '-5px', borderRadius: '50rem' }} position="start">
                            {typeof(icon) === 'string' ? <img src={icon} alt="icon" height="60px" /> : <div style={{width: width <= 768 ? '40px' : '51px', height: width <= 768 ? '40px' : '51px', borderRadius: '40rem', backgroundColor: '#009FE3', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>{icon}</div>}
                        </InputAdornment>
                    ),
                    inputProps: { sx: { paddingLeft: '30px !important' } },
                    style: { paddingLeft: '20px', borderRadius: '40rem' }
                }}
            />
            }
        </>
    )
}

const InputSignUpMemorized = memo(InputSignUp)

export default InputSignUpMemorized