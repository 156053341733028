import Core from "../../Core"
import { useFormik } from 'formik'
import { toast } from "react-toastify"
import colors from "../../../styles/colors"
import { UserRetrieveProps } from '../types'
import * as api from "../../../services/api"
import { RiTruckFill } from "react-icons/ri"
import { IconContext } from "react-icons/lib"
import PaidIcon from '@mui/icons-material/Paid'
import { GiTruck, GiBus } from "react-icons/gi"
import CardSomos from "../../../components/Card"
import { useAuth } from '../../../hooks/useAuth'
import { CompanyProps } from '../../Company/types'
import { useCallback, useEffect, useState } from "react"
import useWindowSize from "../../../hooks/useWindowSize"
import { useParams, useLocation } from "react-router-dom"
import HubRoundedIcon from '@mui/icons-material/HubRounded'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import BlockRoundedIcon from '@mui/icons-material/BlockRounded'
import { DefaultMessage } from "../../../utils/defaultMessages"
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd'
import { FaHelicopter, FaTractor, FaTrailer, FaTruck, FaRegSun } from 'react-icons/fa'
import AdminPanelSettingsRoundedIcon from '@mui/icons-material/AdminPanelSettingsRounded'
import { Alert, Button, Chip, FormControl, Grid, MenuItem, Select, SelectChangeEvent, TextField, Typography } from "@mui/material"

const colheitadeira = require("../../../images/colheitadeiraBranca.png");
const colheitadeiraCinza = require("../../../images/colheitadeiraCinza.png");
// const colheitadeiraBranca = require("../../../images/colheitadeiraBranca.png")
// const colheitadeiraPreta = "https://img.icons8.com/material-rounded/96/harvester.png";

export default function CreateOrUpdateUser(): JSX.Element {
  
  const { id } = useParams()
  const { user } = useAuth()
  const location = useLocation()
  const { width } = useWindowSize()

  const [data, setData] = useState<UserRetrieveProps>()
  const [companies, setCompanies] = useState<CompanyProps[]>()

  const { handleChange, handleSubmit, values, setFieldValue } = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      email: '',
      company: 'company-selected',
      products: [],
      api_user: false,
      blocked: false,
      can_upload_table: false,
      is_representative: false,
      can_manage_query_chassi_or_plate: false,
      is_admin: false,
      plan: '1',
      cr_balance: 0,
      hp_balance: 0,
      sr_balance: 0,
      tr_balance: 0,
      cm_balance: 0,
      hv_balance: 0,
    },
    onSubmit: createOrUpdateUser
  })

  const addProduct = (value: string) => {
    let products: string[] = values.products
    if (products.filter(item => item === value).length > 0) {
      let index = products.indexOf(value)
      products.splice(index, 1)
      setFieldValue('products', products)
    } else {
      products.push(value)
      setFieldValue('products', products)
    }
  }

  const getCompanies = async () => {
    await api.get('company/list/').then((response: any) => {
      if (response.status === 200) {
        setCompanies(response.data.content)
      } else {
        toast.error(response.data.content, {
          position: toast.POSITION.TOP_RIGHT
        })
      }
    }).catch((error) => {
      toast.error(DefaultMessage.ERROR, {
        position: toast.POSITION.TOP_RIGHT
      })
    })
  }

  const getData = useCallback(async () => {
    await api.get(`user/retrieve/${id}/`).then((response: any) => {
      if (response.status === 200) {
        setFieldValue('first_name', response.data.content.first_name)
        setFieldValue('last_name', response.data.content.last_name)
        setFieldValue('email', response.data.content.email)
        setFieldValue('company', response.data.content.company_id ?? 'company-selected')
        setFieldValue('api_content', response.data.content.api_content)
        setFieldValue('api_user', response.data.content.api_user)
        setFieldValue('products', response.data.content.products)
        setFieldValue('blocked', response.data.content.blocked)
        setFieldValue('is_representative', response.data.content.is_representative)
        setFieldValue('is_admin', response.data.content.is_admin)
        setFieldValue('can_upload_table', response.data.content.can_upload_table)
        setFieldValue('can_manage_query_chassi_or_plate', response.data.content.can_manage_query_chassi_or_plate)
        if(response.data.content.is_admin) {
          setFieldValue('plan', '1')
          setFieldValue('cr_balance', 0)
          setFieldValue('hp_balance', 0)
          setFieldValue('tr_balance', 0)
          setFieldValue('sr_balance', 0)
          setFieldValue('cm_balance', 0)
          setFieldValue('hv_balance', 0)
        } else {
          setFieldValue('plan', response.data.content.plan_balance.plan)
          setFieldValue('cr_balance', response.data.content.plan_balance.cr_balance)
          setFieldValue('hp_balance', response.data.content.plan_balance.hp_balance)
          setFieldValue('tr_balance', response.data.content.plan_balance.tr_balance)
          setFieldValue('sr_balance', response.data.content.plan_balance.sr_balance)
          setFieldValue('cm_balance', response.data.content.plan_balance.cm_balance)
          setFieldValue('hv_balance', response.data.content.plan_balance.hv_balance)
        }
        
        setData(response.data)
      } else {
        toast.error(response.data.content, {
          position: toast.POSITION.TOP_RIGHT
        })
      }
    }).catch((error) => {
      toast.error(DefaultMessage.ERROR, {
        position: toast.POSITION.TOP_RIGHT
      })
    })
  }, [id, setFieldValue])

  const handleChangeCompany = (e: SelectChangeEvent<string>) => {
    let value = e.target.value
    setFieldValue('company', value)
    if (value !== 'company-selected') {
      setFieldValue('can_upload_table', false)
      setFieldValue('can_manage_query_chassi_or_plate', false)
      setFieldValue('is_admin', false)
    } else {
      setFieldValue('is_representative', false)
      setFieldValue('api_user', false)
    }
  }

  async function createOrUpdateUser() {
    toast.loading("Aguarde...", {
      position: toast.POSITION.TOP_RIGHT,
    })

    const createUrl = `user/create/`
    const updateUrl = `user/update/${data?.content.id}/`

    let payload

    if (user?.is_staff || user?.is_admin) {
      payload = values
    } else {
        payload = {
          "first_name": values.first_name,
          "last_name": values.last_name,
          "email": values.email,
          "products": values.products,
          "blocked": values.blocked
        }
    }

    if (id) {
      await api.put(updateUrl, payload).then((response: any) => {
        toast.dismiss()
        if (response.status === 200) {
          toast.success("Usuário atualizado com sucesso!", {
            position: toast.POSITION.TOP_RIGHT
          })
        } else {
          toast.error(response.data.content, {
            position: toast.POSITION.TOP_RIGHT
          })
        }
      }).catch((error) => {
        toast.dismiss()
        toast.error(DefaultMessage.ERROR, {
          position: toast.POSITION.TOP_RIGHT
        })
      })
    } else {
      await api.post(createUrl, payload).then((response: any) => {
        toast.dismiss()
        if (response.status === 201) {
          toast.success("Usuário cadastrado com sucesso!", {
            position: toast.POSITION.TOP_RIGHT
          })
        } else {
          toast.error(response.data.content, {
            position: toast.POSITION.TOP_RIGHT
          })
        }
      }).catch((error) => {
        toast.dismiss()
        toast.error(DefaultMessage.ERROR, {
          position: toast.POSITION.TOP_RIGHT
        })
      })
    }
  }

  useEffect(() => {
    if (id) getData()

    if (user?.is_staff || user?.is_admin) {
      getCompanies()

      // @ts-ignore
      if (location.state?.value) {
        // @ts-ignore
        setFieldValue('company', location.state.value.company_id)
      }
    }
  }, [getData, id, user?.is_admin, user?.is_staff, location, setFieldValue])

  return (
    <IconContext.Provider value={{size: '18'}}>
      <Core
        withBlueBar
        withNavbar
        path={
          id
            ? data?.content.company_id
              ? user?.is_staff || user?.is_admin
                ? `/users/${data?.content.company_id}`
                : `/users/company/${data?.content.company_id}`
              : ""
            : ""
        }
        label={
          id
            ? data?.content.company_id
              ? user?.is_staff || user?.is_admin
                ? `Clique aqui para voltar a listagem de usuários da empresa ${data?.content.company_name}`
                : "Clique aqui para voltar a listagem de usuários"
              : ""
            : ""
        }
      >
        <Grid
          container
          direction="column"
          alignContent="center"
          alignItems="center"
          textAlign="center"
          paddingBottom={10}
        >
          <form onSubmit={handleSubmit}>
            <Grid
              container
              spacing={2}
              textAlign="center"
              justifyContent="space-around"
              paddingX={width > 600 ? 20 : 2}
            >
              {
                user?.is_staff || user?.is_admin
                  ? <Grid
                    item
                    md={12} sm={12}
                  >
                    <FormControl>
                      <Select
                        labelId="company-label"
                        id="company-select"
                        name="company"
                        value={values.company}
                        onChange={handleChangeCompany}
                        variant="outlined"
                      >
                        <MenuItem value="company-selected">Selecione a empresa</MenuItem>
                        {
                          companies?.map((item, index) => <MenuItem key={`company-${index}`} value={item.id}>{item.social_reason}</MenuItem>)
                        }
                      </Select>
                    </FormControl>
                  </Grid>
                  : <></>
              }
              <Grid
                item
                md={4} sm={12}
              >
                <Typography variant="body1">
                  Nome
                </Typography>
                <TextField
                  fullWidth
                  required
                  type="text"
                  id="first_name"
                  name="first_name"
                  placeholder="Nome"
                  value={values.first_name}
                  onChange={handleChange}
                />
              </Grid>
              <Grid
                item
                md={4} sm={12}
              >
                <Typography variant="body1">
                  Sobrenome
                </Typography>
                <TextField
                  fullWidth
                  required
                  type="text"
                  id="last_name"
                  name="last_name"
                  placeholder="Sobrenome"
                  value={values.last_name}
                  onChange={handleChange}
                />
              </Grid>
              <Grid
                item
                md={4} sm={12}
              >
                <Typography variant="body1">
                  E-mail
                </Typography>
                <TextField
                  fullWidth
                  required
                  type="text"
                  id="email"
                  name="email"
                  placeholder="E-mail"
                  value={values.email}
                  onChange={handleChange}
                />
              </Grid>
              {
                values.company !== 'company-selected' || user?.is_representative
                  ? <Grid
                    item
                    md={12} xs={12}
                  >
                    <Typography variant="body1">
                      Produtos
                    </Typography>
                    {
                      user?.is_staff
                        ? <>
                          <Chip
                            sx={{ marginX: 1, color: values.products.filter(item => item === "1").length > 0 ? colors.IceWhite : colors.DarkerGray }}
                            icon={<FaTractor />}
                            label="Trator"
                            color={values.products.filter(item => item === "1").length > 0 ? "primary" : "default"}
                            onClick={() => addProduct("1")}
                          />
                          <Chip
                            sx={{ marginX: 1, color: values.products.filter(item => item === "2").length > 0 ? colors.IceWhite : colors.DarkerGray }}
                            icon={<FaHelicopter />}
                            label="Helicóptero"
                            color={values.products.filter(item => item === "2").length > 0 ? "primary" : "default"}
                            onClick={() => addProduct("2")}
                          />
                          <Chip
                            sx={{ marginX: 1, color: values.products.filter(item => item === "3").length > 0 ? colors.IceWhite : colors.DarkerGray }}
                            icon={<FaTrailer />}
                            label="Semirreboque"
                            color={values.products.filter(item => item === "3").length > 0 ? "primary" : "default"}
                            onClick={() => addProduct("3")}
                          />
                          <Chip
                            sx={{ marginX: 1, color: values.products.filter(item => item === "4").length > 0 ? colors.IceWhite : colors.DarkerGray }}
                            icon={<RiTruckFill />}
                            label="Carroceria"
                            color={values.products.filter(item => item === "4").length > 0 ? "primary" : "default"}
                            onClick={() => addProduct("4")}
                          />
                          <Chip
                            sx={{ marginX: 1, color: values.products.filter(item => item === "5").length > 0 ? colors.IceWhite : colors.DarkerGray }}
                            icon={<div style={{display: 'flex', flexDirection: 'column'}}><GiTruck /><GiBus /></div>}
                            label="Caminhão e Ônibus"
                            color={values.products.filter(item => item === "5").length > 0 ? "primary" : "default"}
                            onClick={() => addProduct("5")}
                          />
                          <Chip
                            sx={{ marginX: 1, color: values.products.filter(item => item === "6").length > 0 ? colors.IceWhite : colors.DarkerGray }}
                            icon={<div style={{display: 'flex', flexDirection: 'column'}}>{values.products.filter(item => item === "6").length > 0 ? <img width="15" height="14" src={colheitadeira} alt="harvester" /> : <img width="16" height="14" src={colheitadeiraCinza} alt="harvester" />}</div>}
                            label="Colheitadera"
                            color={values.products.filter(item => item === "6").length > 0 ? "primary" : "default"}
                            onClick={() => addProduct("6")}
                          />
                        </>
                        : <>
                          {
                            user!.products.filter(item => item === "1").length > 0
                              ? <Chip
                                sx={{ marginX: 1, color: values.products.filter(item => item === "1").length > 0 ? colors.IceWhite : colors.DarkerGray }}
                                icon={<FaTractor />}
                                label="Trator"
                                color={values.products.filter(item => item === "1").length > 0 ? "primary" : "default"}
                                onClick={() => addProduct("1")}
                              />
                              : <></>
                          }
                          {
                            user!.products.filter(item => item === "2").length > 0
                              ? <Chip
                                sx={{ marginX: 1, color: values.products.filter(item => item === "2").length > 0 ? colors.IceWhite : colors.DarkerGray }}
                                icon={<FaHelicopter />}
                                label="Helicóptero"
                                color={values.products.filter(item => item === "2").length > 0 ? "primary" : "default"}
                                onClick={() => addProduct("2")}
                              />
                              : <></>
                          }
                          {
                            user!.products.filter(item => item === "3").length > 0
                              ? <Chip
                                sx={{ marginX: 1, color: values.products.filter(item => item === "3").length > 0 ? colors.IceWhite : colors.DarkerGray }}
                                icon={<FaTrailer />}
                                label="Semirreboque"
                                color={values.products.filter(item => item === "3").length > 0 ? "primary" : "default"}
                                onClick={() => addProduct("3")}
                              />
                              : <></>
                          }
                          {
                            user!.products.filter(item => item === "4").length > 0
                              ? <Chip
                                sx={{ marginX: 1, color: values.products.filter(item => item === "4").length > 0 ? colors.IceWhite : colors.DarkerGray }}
                                icon={<RiTruckFill />}
                                label="Carroceria"
                                color={values.products.filter(item => item === "4").length > 0 ? "primary" : "default"}
                                onClick={() => addProduct("4")}
                              />
                              : <></>
                          }
                          {
                            user!.products.filter(item => item === "5").length > 0
                              ? <Chip
                                sx={{ marginX: 1, color: values.products.filter(item => item === "5").length > 0 ? colors.IceWhite : colors.DarkerGray }}
                                icon={<div style={{display: 'flex', flexDirection: 'column'}}><GiTruck /><GiBus /></div>}
                                label="Caminhão e Ônibus"
                                color={values.products.filter(item => item === "5").length > 0 ? "primary" : "default"}
                                onClick={() => addProduct("5")}
                              />
                              : <></>
                          }
                          {
                            user!.products.filter(item => item === "6").length > 0
                              ? <Chip
                                sx={{ marginX: 1, color: values.products.filter(item => item === "6").length > 0 ? colors.IceWhite : colors.DarkerGray }}
                                icon={<div style={{display: 'flex', flexDirection: 'column'}}>{values.products.filter(item => item === "6").length > 0 ? <img width="15" height="12" src={colheitadeira} alt="harvester" /> : <img width="18" height="18" src="https://img.icons8.com/material-rounded/96/harvester.png" alt="harvester" />}</div>}
                                label="Colheitadera"
                                color={values.products.filter(item => item === "6").length > 0 ? "primary" : "default"}
                                onClick={() => addProduct("6")}
                              />
                              : <></>
                          }

                        </>
                    }
                  </Grid>
                  : <></>
              }
              {
                user?.is_staff || user?.is_admin
                  ? <>
                    {
                      values.company !== 'company-selected'
                        ? <Grid
                          item
                          md={3} xs={12}
                        >
                          <Typography variant="body1">
                            É o responsável pela empresa?
                          </Typography>
                          <Chip
                            sx={{ marginX: 1, color: values.is_representative ? colors.IceWhite : colors.DarkerGray }}
                            icon={<AssignmentIndIcon />}
                            label={values.is_representative ? "Sim" : "Não"}
                            color={values.is_representative ? "primary" : "default"}
                            onClick={() => setFieldValue('is_representative', !values.is_representative)}
                          />
                        </Grid>
                        : <></>
                    }
                    {
                      values.company !== 'company-selected'
                        ? <Grid
                          item
                          md={3} xs={12}
                        >
                          <Typography variant="body1">
                            Permitir integração?
                          </Typography>
                          <Chip
                            sx={{ marginX: 1, color: values.api_user ? colors.IceWhite : colors.DarkerGray }}
                            icon={<HubRoundedIcon />}
                            label={values.api_user ? "Sim" : "Não"}
                            color={values.api_user ? "primary" : "default"}
                            onClick={() => setFieldValue('api_user', !values.api_user)}
                          />
                        </Grid>
                        : <></>
                    }
                    {
                      user?.is_staff && values.company === 'company-selected'
                        ? <>
                          <Grid
                            item
                            md={3} xs={12}
                          >
                            <Typography variant="body1">
                              Administrador?
                            </Typography>
                            <Chip
                              sx={{ marginX: 1, color: values.is_admin ? colors.IceWhite : colors.DarkerGray }}
                              icon={<AdminPanelSettingsRoundedIcon />}
                              label={values.is_admin ? "Sim" : "Não"}
                              color={values.is_admin ? "primary" : "default"}
                              onClick={() => {
                                if (!values.is_admin === false) {
                                  setFieldValue('can_upload_table', false)
                                  setFieldValue('can_manage_query_chassi_or_plate', false)
                                }
                                setFieldValue('is_admin', !values.is_admin)
                              }}
                            />
                          </Grid>
                          {
                            values.is_admin
                              ? <><Grid
                                item
                                md={3} xs={12}
                              >
                                <Typography variant="body1">
                                  Pode adicionar planilhas?
                                </Typography>
                                <Chip
                                  sx={{ marginX: 1, color: values.can_upload_table ? colors.IceWhite : colors.DarkerGray }}
                                  icon={<CloudUploadIcon />}
                                  label={values.can_upload_table ? "Sim" : "Não"}
                                  color={values.can_upload_table ? "primary" : "default"}
                                  onClick={() => setFieldValue('can_upload_table', !values.can_upload_table)}
                                />
                              </Grid>
                              <Grid
                              item
                              md={3} xs={12}
                            >
                              <Typography variant="body1">
                                Pode Gerenciar Precificador?
                              </Typography>
                              <Chip
                                sx={{ marginX: 1, color: values.can_manage_query_chassi_or_plate ? colors.IceWhite : colors.DarkerGray }}
                                icon={<FaRegSun />}
                                label={values.can_manage_query_chassi_or_plate ? "Sim" : "Não"}
                                color={values.can_manage_query_chassi_or_plate ? "primary" : "default"}
                                onClick={() => setFieldValue('can_manage_query_chassi_or_plate', !values.can_manage_query_chassi_or_plate)}
                              />
                            </Grid></>
                              : <></>
                          }
                        </>
                        : <></>
                    }
                  </>
                  : <></>
              }
              <Grid
                item
                md={3} xs={12}
              >
                <Typography variant="body1">
                  Bloquear?
                </Typography>
                <Chip
                  icon={<BlockRoundedIcon />}
                  label={values.blocked ? "Sim" : "Não"}
                  color={values.blocked ? "primary" : "default"}
                  onClick={() => setFieldValue('blocked', !values.blocked)}
                  sx={{ marginX: 1, color: values.blocked ? colors.IceWhite : colors.DarkerGray }}
                />
              </Grid>
              {
                user?.is_staff && values.company === 'company-selected' && values.is_admin !== true
                  ?
                  <Grid
                    item
                    md={3} sm={12} xs={12}
                  >
                    <Typography variant="body1">
                      Qual tipo de plano?
                    </Typography>
                    <Chip
                      icon={<PaidIcon />}
                      label={values.plan === '1' ? "Free" : values.plan === '2' ? "Pré pago" : "Pós pago"}
                      color={values.plan === '1' ? "success" : values.plan === '2' ? "primary" : "warning"}
                      onClick={() => setFieldValue('plan', values.plan === '1' ? '2' : values.plan === '2' ? '3' : '1')}
                      sx={{ color: values.plan === '1' ? colors.IceWhite : values.plan === '2' ? colors.IceWhite : colors.DarkerGray }}
                    />
                  </Grid>
                  : <></>
              }
              {
                user?.is_staff && values.company === 'company-selected' && values.is_admin !== true
                  ? <CardSomos>
                    <Grid
                      container
                      spacing={4}
                      textAlign="center"
                      justifyContent="center"
                    >
                      <Grid
                        item
                        md={12} sm={12} xs={12}
                      >
                        <Typography variant="h6">
                          PRODUTOS
                        </Typography>
                        <Alert
                          variant="filled"
                          severity="info"
                          icon="💡"
                        >
                          <h4>Informe abaixo a quantidade {values.plan === '1' ? 'diária' : 'mensal'} para cada produto.</h4>
                          <ol style={{ textAlign: "left" }}>
                            <li>
                              <strong>FREE:</strong> O sistema irá considerar o valor informado como créditos, que serão descontados na medida em que é as consultas são realizadas, e renovado todos os dias. Quando os créditos do dia acabarem a empresa não conseguirá mais realizar consultas.
                            </li>
                            <li>
                              <strong>PRÉ PAGO:</strong> O sistema irá considerar o valor informado como <strong>créditos</strong>, que serão descontados na medida em que é as consultas são realizadas, sem prazo para expirar, quando os créditos acabarem, a empresa não conseguirá mais realizar consultas.
                            </li>
                            <li>
                              <strong>PÓS PAGO:</strong> O valor informado servirá apenas como identificação do plano onde o cliente poderá acompanhar seu consumo na tela inicial. Após atingir o valor informado, a empresa ainda poderá realizar consultas, porém ciente que entrará como consulta avulsa. Uma cobrança mensal deve ser feita de acordo com o contrato firmado com o cliente, não se esqueça de emitir um <strong>boleto</strong> para pagamento todo mês.
                            </li>
                          </ol>
                        </Alert>
                      </Grid>
                      <Grid
                        item
                        md={4} sm={12} xs={12}
                      >
                        <Chip
                          label="Trator"
                          icon={<FaTractor />}
                          onClick={() => addProduct("1")}
                          color={values.products.filter(item => item === "1").length > 0 ? "primary" : "secondary"}
                          sx={{ marginX: 1, color: values.products.filter(item => item === "1").length > 0 ? colors.IceWhite : colors.DarkerGray }}
                        />
                        <Typography variant="body1">
                          Quantidade
                        </Typography>
                        <TextField
                          fullWidth
                          required
                          type="number"
                          id="tr_balance"
                          name="tr_balance"
                          onChange={handleChange}
                          className="clientInput"
                          value={values.tr_balance}
                          placeholder="Informe um número de consultas aqui"
                          disabled={values.products.filter(item => item === "1").length === 0}
                        />
                      </Grid>
                      <Grid
                        item
                        md={4} sm={12} xs={12}
                      >
                        <Chip
                          label="Helicóptero"
                          icon={<FaHelicopter />}
                          onClick={() => addProduct("2")}
                          color={values.products.filter(item => item === "2").length > 0 ? "primary" : "secondary"}
                          sx={{ marginX: 1, color: values.products.filter(item => item === "2").length > 0 ? colors.IceWhite : colors.DarkerGray }}
                        />
                        <Typography variant="body1">
                          Quantidade
                        </Typography>
                        <TextField
                          required
                          fullWidth
                          type="number"
                          id="hp_balance"
                          name="hp_balance"
                          onChange={handleChange}
                          className="clientInput"
                          value={values.hp_balance}
                          placeholder="Informe um número de consultas aqui"
                          disabled={values.products.filter(item => item === "2").length === 0}
                        />
                      </Grid>
                      <Grid
                        item
                        md={4} sm={12} xs={12}
                      >
                        <Chip
                          icon={<FaTrailer />}
                          label="Semirreboque"
                          onClick={() => addProduct("3")}
                          color={values.products.filter(item => item === "3").length > 0 ? "primary" : "secondary"}
                          sx={{ marginX: 1, color: values.products.filter(item => item === "3").length > 0 ? colors.IceWhite : colors.DarkerGray }}
                        />
                        <Typography variant="body1">
                          Quantidade
                        </Typography>
                        <TextField
                          required
                          fullWidth
                          type="number"
                          id="sr_balance"
                          name="sr_balance"
                          onChange={handleChange}
                          className="clientInput"
                          value={values.sr_balance}
                          placeholder="Informe um número de consultas aqui"
                          disabled={values.products.filter(item => item === "3").length === 0}
                        />
                      </Grid>
                      <Grid
                        item
                        md={4} sm={12} xs={12}
                      >
                        <Chip
                          icon={<FaTruck />}
                          label="Carroceria"
                          onClick={() => { addProduct("4") }}
                          color={values.products.filter(item => item === "4").length > 0 ? "primary" : "secondary"}
                          sx={{ marginX: 1, color: values.products.filter(item => item === "4").length > 0 ? colors.IceWhite : colors.DarkerGray }}
                        />
                        <Typography variant="body1">
                          Quantidade
                        </Typography>
                        <TextField
                          required
                          fullWidth
                          type="number"
                          id="cr_balance"
                          name="cr_balance"
                          onChange={handleChange}
                          className="clientInput"
                          value={values.cr_balance}
                          placeholder="Informe um número de consultas aqui"
                          disabled={values.products.filter(item => item === "4").length === 0}
                        />
                      </Grid>
                      <Grid
                        item
                        md={4} sm={12} xs={12}
                      >
                        <Chip
                          label="Caminhão e Ônibus"
                          icon={<div style={{display: 'flex', flexDirection: 'column'}}><GiTruck /><GiBus /></div>}
                          onClick={() => { addProduct("5") }}
                          color={values.products.filter(item => item === "5").length > 0 ? "primary" : "secondary"}
                          sx={{ marginX: 1, color: values.products.filter(item => item === "5").length > 0 ? colors.IceWhite : colors.DarkerGray }}
                        />
                        <Typography variant="body1">
                          Quantidade
                        </Typography>
                        <TextField
                          required
                          fullWidth
                          type="number"
                          id="cm_balance"
                          name="cm_balance"
                          onChange={handleChange}
                          className="clientInput"
                          value={values.cm_balance}
                          placeholder="Informe um número de consultas aqui"
                          disabled={values.products.filter(item => item === "5").length === 0}
                        />
                      </Grid>
                      <Grid
                        item
                        md={4} sm={12} xs={12}
                      >
                        <Chip
                          label="Colheitadera"
                          icon={<div style={{display: 'flex', flexDirection: 'column'}}>{values.products.filter(item => item === "6").length > 0 ? <img width="15" height="12" src={colheitadeira} alt="harvester" /> : <img width="18" height="18" src="https://img.icons8.com/material-rounded/96/harvester.png" alt="harvester" />}</div>}
                          onClick={() => { addProduct("6") }}
                          color={values.products.filter(item => item === "6").length > 0 ? "primary" : "secondary"}
                          sx={{ marginX: 1, color: values.products.filter(item => item === "6").length > 0 ? colors.IceWhite : colors.DarkerGray }}
                        />
                        <Typography variant="body1">
                          Quantidade
                        </Typography>
                        <TextField
                          required
                          fullWidth
                          type="number"
                          id="hv_balance"
                          name="hv_balance"
                          onChange={handleChange}
                          className="clientInput"
                          value={values.hv_balance}
                          placeholder="Informe um número de consultas aqui"
                          disabled={values.products.filter(item => item === "6").length === 0}
                        />
                      </Grid>
                    </Grid>
                  </CardSomos>
                  : <></>
              }
              <Grid
                item
                md={12} xs={12}
              >
                <Button
                  className="btn-inverse"
                  variant="contained"
                  size="large"
                  color="secondary"
                  type="submit"
                >
                  {
                    id ? "Atualizar" : "Cadastrar"
                  }
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Core>
    </IconContext.Provider>
  )

}