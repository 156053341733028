import SignRoutes from "./SignRoutes"
import UserRoutes from "./UserRoutes"
import FirstLogin from "./FirstLogin"
import AdminRoutes from "./AdminRoutes"
import StaffRoutes from "./StaffRoutes"
import { useAuth } from "../hooks/useAuth"
import AdminTableRoutes from "./AdminTableRoutes"
import RepresentativeRoutes from "./RepresentativeRoutes"

export default function AppRoutes(): JSX.Element {
	const { signed, user } = useAuth()

	return signed
		? user?.first_login
				? <FirstLogin /> 
				: user?.is_staff
					? <StaffRoutes />
					: user?.can_upload_table
						? <AdminTableRoutes />
						: user?.is_admin
							? <AdminRoutes />
							: user?.is_representative
								? <RepresentativeRoutes />
								: <UserRoutes />
		: <SignRoutes/>
}