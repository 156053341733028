import axios, { AxiosRequestConfig } from "axios"
import {cookies} from "../hooks/useCookies"

export const baseURL = process.env.REACT_APP_API_URL

const api = axios.create({
	baseURL
})

async function refreshToken() {

	interface Response {
		access: string
		refresh: string
	}

	return new Promise(async (resolve) => {
		await api.post(
			'/user/token/refresh',
			{"refresh": cookies.get('@Somos:refresh')}
		).then((response) => {
			const responseData = response.data as Response
			cookies.set('@Somos:refresh', responseData.refresh, { path: '/', sameSite: 'lax' })
			cookies.set('@Somos:access', responseData.access, { path: '/', sameSite: 'lax' })
			defaults.headers.Authorization = `Bearer ${responseData.access}`
			resolve(true)
		}).catch((error) => {
			cookies.remove('@Somos:user')
			cookies.remove('@Somos:access')
			cookies.remove('@Somos:refresh')
			window.location.pathname = '/'
			window.location.reload()
		})
	})
}

export function get(path: string, data?: object, content_type?: string) {

	let _defaults = {
		'headers': {
			'Authorization': defaults.headers!.Authorization,
			'Content-Type': defaults.headers!["Content-Type"],
		}
	}

	const headers = _defaults.headers;
	const params = data;
	
	const config: AxiosRequestConfig = {
		params, 
		headers
	}

	return new Promise(async (resolve) => {
		await api.get(path, config).then((response) => {
			resolve(response)
		}).catch(async (error) => {
			if (error.response.status === 401) {
				var res = await refreshToken()
				if (res) {
					await api.get(path, config).then((response) => {
						resolve(response)
					}).catch((error) => {
						resolve(error.response)
					})
				}
			} else {
				resolve(error.response)
			}
		})
	})
}

export function destroy(path: string) {
	return new Promise(async (resolve) => {
		await api.delete(path, defaults).then((response) => {
			resolve(response)
		}).catch(async (error) => {
			if (error.response.status === 401) {
				var res = await refreshToken()
				if (res) {
					await api.delete(path, defaults).then((response) => {
						resolve(response)
					}).catch((error) => {
						resolve(error.response)
					})
				}
			} else {
				resolve(error.response)
			}
		})
	})
}

export function put(path: string, data: object) {
	return new Promise(async (resolve) => {
		await api.put(path, data, defaults).then((response) => {
			resolve(response)
		}).catch(async (error) => {
			if (error.response.status === 401) {
				var res = await refreshToken()
				if (res) {
					await api.put(path, data, defaults).then((response) => {
						resolve(response)
					}).catch((error) => {
						resolve(error.response)
					})
				}
			} else {
				resolve(error.response)
			}
		})
	})
}

export function post(path: string, data: object, content_type?: string) {
	let _defaults = {
		'headers': {
			'Authorization': defaults.headers!.Authorization,
			'Content-Type': defaults.headers!["Content-Type"]
		}
	}
	if (content_type) {
		_defaults.headers['Content-Type'] = content_type
	}
	return new Promise(async (resolve) => {
		await api.post(path, data, _defaults).then((response) => {
			resolve(response)
		}).catch(async(error) => {
			if (error.response.status === 401 && error.response.data.detail !== "Usuário e/ou senha incorreto(s)") {
				var res = await refreshToken()
				if (res) {
					await api.put(path, data, defaults).then((response) => {
						resolve(response)
					}).catch((error) => {
						resolve(error.response)
					})
				}
			} else {
				resolve(error.response)
			}
		})
	})
}

export const defaults = {
	'headers': {
		'Authorization': '',
		'Content-Type': 'application/json'
	}
}
