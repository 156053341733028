import { styled } from '@mui/system';
import colors from "../../styles/colors";
import { useAuth } from "../../hooks/useAuth";
import MenuIcon from '@mui/icons-material/Menu';
import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import useWindowSize from "../../hooks/useWindowSize";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { FormControl, Select, MenuItem, AppBar, Container, Toolbar, Button, Box, IconButton, Avatar, Tooltip, Menu, Typography } from '@mui/material';

// Estilizando a seta
const CustomArrow = styled(ArrowDropDownIcon)({
  color: 'blue', // Altere para a cor que deseja
});


type MenuProps = {
  name: string;
  path?: string;
  paths?: MenuProps[];
};

type OptionKeys = 'pricing' | 'search-engines' | 'new-table';

export default function NavBar(): JSX.Element {

  const translatePage = (nameParams: string | null): string => {

    const name = nameParams?.replace('/', '');

    if (!name) {
      return 'GERENCIAMENTO';
    }

    const options: Record<OptionKeys, string> = {
      'pricing': 'PRECIFICADOR',
      'search-engines': 'BUSCASDORES',
      'new-table': 'PLANILHAS'
    }

    return options[name as OptionKeys] || 'GERENCIAMENTO';

  }

  const navigate = useNavigate();
  const { width } = useWindowSize();
  const { user, Logout, content, getUserData } = useAuth();
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [activeButton, setActiveButton] = useState<string | null>(localStorage.getItem('navbarState'));
  const [selectedOption, setSelectedOption] = useState<string>(localStorage.getItem('navbarState') === null ? 'GERENCIAMENTO' : translatePage(localStorage?.getItem('navbarState')));
  const [somosCode, setSomosCode] = useState<number | string>('all');
  const [pagePaths, setPagePaths] = useState<MenuProps[] | undefined>([]);

  useEffect(() => {
    getUserData(user?.id)
  }, [getUserData, user?.id]);

  const handleSelectChange = (event: any) => {
    const path = event.target.value as string;
    setSelectedOption(path);
    handleButtonClick(path);
  };

  const handleButtonClick = (path: string) => {
    setActiveButton(path);
    localStorage.setItem("navbarState", path);
    navigate(path);
    handleCloseNavMenu();
  };

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const pageChange = (event: any) => {
    setSelectedOption(event.target.value);
  }

  const renderButtonOrSelect = (page: MenuProps) => {

    if (page.name === 'Gerenciamento') {
      //setPagePaths(page.paths);
      return (
        <FormControl sx={{ width: 'max-content', height: '4.5rem', padding: '0', margin: '0' }}>
          <Select
            value={'GERENCIAMENTO'}
            onChange={(e) => pageChange(e)}
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
            IconComponent={CustomArrow}
            sx={{
              color: 'white',
              background: '#252028',
              borderRadius: 1,
              fontSize: '1rem',
              width: 'max-content',
              margin: '0',
              padding: '0',
            }}
            MenuProps={{
              PaperProps: {
                sx: {
                  backgroundColor: '#252028',
                  color: 'white',
                  fontSize: '0.875rem',
                },
              },
            }}
          >
            <MenuItem
              key={"option.path"}
              value={'GERENCIAMENTO'}
              hidden
              sx={{
                fontSize: '0.875rem',
                '&:hover': {
                  backgroundColor: '#333',
                },
              }}>
              <div
                style={{
                  color: `${translatePage(localStorage?.getItem('navbarState')) !== 'GERENCIAMENTO' ? 'rgb(0, 174, 239)' : 'white'}`,
                  textDecoration: 'none',
                  display: 'block',
                  width: 'max-content',
                  padding: '0.5rem 0rem',
                  fontSize: width > 1210 ? '1rem' : '.6rem',
                }}
              >
                GERENCIAMENTO
              </div>
            </MenuItem>
            {page.paths?.map((option: MenuProps) => (
              option.name !== 'GERENCIAMENTO' && ( // Condição para não exibir GERENCIAMENTO na lista
                <MenuItem
                  key={option.path}
                  value={option.path}
                  sx={{
                    fontSize: width > 1210 ? '1rem' : '.6rem',
                    '&:hover': {
                      backgroundColor: '#333',
                    },
                  }}
                  onClick={() => handleButtonClick(option.path || '/')}
                >
                  <Link
                    to={option.path || '/'}
                    style={{
                      color: activeButton === option.path ? '#00AEEF' : 'white',
                      textDecoration: 'none',
                      display: 'block',
                      width: 'max-content',
                      padding: '0.5rem 0rem',
                      fontSize: width > 1210 ? '1rem' : '.6rem',
                    }}
                  >
                    {option.name}
                  </Link>
                </MenuItem>
              )
            ))}

          </Select>
        </FormControl>
      );
    } else {
      return (
        <Link
          to={page.path || '/'}
          style={{ color: activeButton === page.path ? '#00AEEF' : 'white', height: '100%', padding: '0rem 0' }}
          onClick={() => handleButtonClick(page.path || '/')}
        >
          {page.name}
        </Link>
      );
    }
  };

  const pagesDesktopRepresentative: MenuProps[] = [
    { name: 'Minha empresa', path: `/company/${user?.company_id}` },
    { name: 'Usuários', path: `/users/company/${user?.company_id}` },
    { name: 'Histórico', path: '/histories' },
    { name: 'Estatísticas', path: '/statistics' }
  ];

  const pagesDesktopAdmin: MenuProps[] = [
    { name: 'Empresas', path: '/companies' },
    { name: 'Usuários', path: '/userslist/' },
    { name: 'Histórico', path: '/histories' },
    { name: 'Documentação', path: '/doc' },
    { name: 'Estatísticas', path: '/statistics' }
  ];

  const pagesDesktopAdminTable: MenuProps[] = [
    { name: 'Empresas', path: '/companies' },
    { name: 'Usuários', path: '/userslist/' },
    { name: 'Histórico', path: '/histories' },
    { name: 'Documentação', path: '/doc' },
    { name: 'Estatísticas', path: '/statistics' },
    {
      name: 'Gerenciamento',
      paths: [{ name: 'PLANILHAS', path: '/new-table' }, { name: 'BUSCADORES', path: '/search-engines' }]
    }
  ];

  const pagesDesktopAdminPricing: MenuProps[] = pagesDesktopAdminTable.map(item => ({
    ...item,
    paths: item.paths ? [...item.paths] : undefined
  }));

  const gerenciamentoIndex = pagesDesktopAdminPricing.findIndex(item => item.name === 'Gerenciamento');
  if (gerenciamentoIndex !== -1) {
    pagesDesktopAdminPricing[gerenciamentoIndex].paths?.push({
      name: 'PRECIFICADOR',
      path: '/pricing'
    });
  }

  const pagesDesktopAdminTablePricing: MenuProps[] = pagesDesktopAdminPricing.map(item => ({
    ...item,
    paths: item.paths ? [...item.paths] : undefined
  }));

  const pagesUser: MenuProps[] = [
    { name: 'Histórico', path: `/histories` },
    { name: 'Estatísticas', path: '/statistics' }
  ];

  const settings: MenuProps[] = [
    { name: 'Minha conta', path: `/my-account/${user?.id}` }
  ];

  return (
    <AppBar position="fixed" sx={{ backgroundColor: `${colors.DarkerGray}`, height: '81.33px', justifyContent: 'center' }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Link onClick={() => handleButtonClick("")} to={`/`}>
            <img src={require("../../images/horizontalLogo.png")} width={width > 355 ? 300 : 250} alt="Logo" />
          </Link>
          {

            width >= 990 ?

              user?.is_admin && content?.can_manage_query_chassi_or_plate && content?.can_upload_table ? (
                <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                  {pagesDesktopAdminTablePricing.map((page) => (
                    <Button
                      className="boxButton"
                      key={page.name}
                      sx={{ my: 2, display: 'block', color: activeButton === page.name ? '#00AEEF' : 'white' }}
                    >
                      {renderButtonOrSelect(page)}
                    </Button>
                  ))}
                </Box>
              ) : user?.is_admin && content?.can_manage_query_chassi_or_plate ? (
                <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                  {pagesDesktopAdminPricing.map((page) => (
                    <Button
                      className="boxButton"
                      key={page.name}
                      sx={{ my: 2, display: 'block', color: activeButton === page.name ? '#00AEEF' : 'white' }}
                    >
                      {renderButtonOrSelect(page)}
                    </Button>
                  ))}
                </Box>
              ) : user?.is_admin && user?.can_upload_table ? (
                <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                  {pagesDesktopAdminTable.map((page) => (
                    <Button
                      className="boxButton"
                      key={page.name}
                      onClick={() => handleButtonClick(page.name)}
                      sx={{ my: 2, color: 'white', display: 'block' }}
                    >
                      {renderButtonOrSelect(page)}
                    </Button>
                  ))}
                </Box>
              ) : user?.is_admin ? (
                <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                  {pagesDesktopAdmin.map((page) => (
                    <Button
                      className="boxButton"
                      key={page.name}
                      sx={{ my: 2, color: 'white', display: 'block' }}
                    >
                      {renderButtonOrSelect(page)}
                    </Button>
                  ))}
                </Box>
              ) : user?.is_representative ? (
                <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                  {pagesDesktopRepresentative.map((page) => (
                    <Button
                      className="boxButton"
                      key={page.name}
                      sx={{ my: 2, color: 'white', display: 'block' }}
                    >
                      {renderButtonOrSelect(page)}
                    </Button>
                  ))}
                </Box>
              ) : (
                <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                  {pagesUser.map((page) => (
                    <Button
                      className="boxButton"
                      key={page.name}
                      sx={{ my: 2, color: 'white', display: 'block' }}
                    >
                      {renderButtonOrSelect(page)}
                    </Button>
                  ))}
                </Box>
              )

              :

              <Box sx={{ flexGrow: 1, display: { xs: 'flex' }, justifyContent: 'end' }} >
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="primary"
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: 'block', md: 'none' },
                  }}
                >
                  <label style={{ marginLeft: 10, marginRight: 10, color: colors.WaterBlue }}>
                    <strong>Bem vindo(a), {user?.first_name} {user?.last_name} 😃</strong>
                  </label>
                  {
                    user?.is_admin && content?.can_manage_query_chassi_or_plate && content?.can_upload_table ?
                      pagesDesktopAdminTablePricing.map((page, index) => (
                        <MenuItem key={page.name} onClick={handleCloseNavMenu}>
                          <Link to={page.path || '/'} className="btn-link">
                            <Typography textAlign="center">{page.name}</Typography>
                          </Link>
                        </MenuItem>
                      ))
                      : user?.is_admin && content?.can_manage_query_chassi_or_plate ?
                        pagesDesktopAdminPricing.map((page, index) => (
                          <MenuItem key={page.name} onClick={handleCloseNavMenu}>
                            <Link to={page.path || '/'} className="btn-link">
                              <Typography textAlign="center">{page.name}</Typography>
                            </Link>
                          </MenuItem>
                        ))
                        : user?.is_admin && user?.can_upload_table ?
                          pagesDesktopAdminTable.map((page, index) => (
                            <MenuItem key={page.name} onClick={handleCloseNavMenu}>
                              <Link to={page.path || '/'} className="btn-link">
                                <Typography textAlign="center">{page.name}</Typography>
                              </Link>
                            </MenuItem>
                          ))
                          : user?.is_admin ?
                            pagesDesktopAdmin.map((page, index) => (
                              <MenuItem key={page.name} onClick={handleCloseNavMenu}>
                                <Link to={page.path || '/'} className="btn-link">
                                  <Typography textAlign="center">{page.name}</Typography>
                                </Link>
                              </MenuItem>
                            ))
                            : user?.is_representative ?
                              pagesDesktopRepresentative.map((page, index) => (
                                <MenuItem key={page.name} onClick={handleCloseNavMenu}>
                                  <Link to={page.path || '/'} className="btn-link">
                                    <Typography textAlign="center">{page.name}</Typography>
                                  </Link>
                                </MenuItem>
                              ))
                              : pagesUser.map((page, index) => (
                                <MenuItem key={page.name} onClick={handleCloseNavMenu}>
                                  <Link to={page.path || '/'} className="btn-link">
                                    <Typography textAlign="center">{page.name}</Typography>
                                  </Link>
                                </MenuItem>
                              ))
                  }

                  <MenuItem key={'settings'} onClick={handleCloseNavMenu}>
                    <Link to={`/my-account/${user?.id}` || '/'} className="btn-link">
                      <Typography textAlign="center">Minha conta</Typography>
                    </Link>
                  </MenuItem>

                  <MenuItem key='sair' onClick={
                    () => {
                      Logout()
                      navigate("/")
                    }
                  }
                  >
                    <Typography textAlign="center">Sair</Typography>
                  </MenuItem>
                </Menu>
              </Box>
          }
          {
            width >= 990 ?
              <Box sx={{ flexGrow: 0 }}>
                <strong style={{ color: 'white' }}>{user?.first_name} 😃</strong>
                <Tooltip title="Usuário">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar alt={user?.first_name} src={''} />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: '45px' }}
                  anchorEl={anchorElUser}
                  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                  keepMounted
                  transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  <label style={{ marginLeft: 10, marginRight: 10, color: colors.WaterBlue }}>
                    <strong>Bem vindo, {user?.first_name} {user?.last_name} 😃</strong>
                  </label>
                  {settings.map((setting) => (
                    <MenuItem key={setting.name} onClick={() => handleButtonClick(setting.path || '')}>
                      <Typography textAlign="center">{setting.name}</Typography>
                    </MenuItem>
                  ))}
                  <MenuItem onClick={() => Logout()}>
                    <Typography textAlign="center">Sair</Typography>
                  </MenuItem>
                </Menu>
              </Box> : <></>
          }


        </Toolbar>
      </Container>
    </AppBar>
  );
}