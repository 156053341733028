/* eslint-disable react-hooks/exhaustive-deps */
import Core from "../Core"
import { useFormik } from 'formik'
import { LoggerProps } from "./types"
import { toast } from "react-toastify"
import * as api from "../../services/api"
import { IJsonSheet } from "json-as-xlsx"
import { RiTruckFill } from "react-icons/ri"
import { baseURL } from "../../services/api"
import { useAuth } from "../../hooks/useAuth"
import { CompanyProps } from "../Company/types"
import { GiTruck, GiBus } from "react-icons/gi"
import { MdSimCardDownload } from "react-icons/md"
import useWindowSize from "../../hooks/useWindowSize"
import { DefaultMessage } from "../../utils/defaultMessages"
import React, { useCallback, useEffect, useState } from "react"
import { FaHelicopter, FaTractor, FaTrailer } from 'react-icons/fa'
import ListItemValues from "../../components/History/ListItemValues"
import ListItemNotValues from "../../components/History/ListItemNotValues"
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch'
import { Alert, Button, Chip, CircularProgress, FormControl, Grid, List, MenuItem, Select, TextField, Typography } from "@mui/material"

const whiteHarvest = require("../../images/whiteHarvest.png")

const now = new Date().toISOString().split('T')[0]
const thirtyDaysAgo = new Date(new Date().setDate(new Date().getDate() - 30)).toISOString().split('T')[0]

const getTodayDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const day = String(today.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

export default function Histories(): JSX.Element {
  const { user } = useAuth()
  const { width } = useWindowSize()
  const [, setExcelData] = useState<IJsonSheet[]>()
  const [valueSearch, setValueSearch] = useState<string>('')
  const [companies, setCompanies] = useState<CompanyProps[]>()
  const [data, setData] = useState<undefined | LoggerProps[]>()
  const [indexSelected, setIndexSelected] = useState<number>(1)
  const [productsIncluded, setProductsIncluded] = useState<string[]>([])
  const [dataSearch, setDataSearch] = useState<undefined | LoggerProps[]>()

  const todayDate = getTodayDate();

  const { handleChange, handleSubmit, values } = useFormik({
    initialValues: {
      initialDate: thirtyDaysAgo,
      finalDate: now,
      company: 'all-companies'
    },
    onSubmit: getLogs
  })

  const includeProduct = useCallback(
    (value: string) => {
      setProductsIncluded((prevProductsIncluded) => {
        setValueSearch('')
        let updatedProductsIncluded: string[]
        if (prevProductsIncluded.includes(value)) {
          updatedProductsIncluded = prevProductsIncluded.filter(
            (item) => item !== value
          )
        } else {
          updatedProductsIncluded = [...prevProductsIncluded, value]
        }
        const filteredDataSearch = data?.filter((item) => {
          if (updatedProductsIncluded.length === 0) {
            return true
          }
          if ('values' in item.response) {
            return item.response.values.some((subItem) =>
              updatedProductsIncluded.includes(subItem.type)
            )
          } else {
            return (
              item.response.type &&
              updatedProductsIncluded.includes(item.response.type)
            )
          }
        })

        setDataSearch(filteredDataSearch)

        return updatedProductsIncluded
      })
    }, [data]
  )
  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value
    setValueSearch(value)
    // Chamar a função de busca
    search(value)
  }


  const search = useCallback(
    (query: string = '') => {
      let items = data

      // Aplicar filtro de productsIncluded
      if (productsIncluded.length > 0) {
        items = items?.filter((item) => {
          if ('values' in item.response) {
            return item.response.values.some((subItem) =>
              productsIncluded.includes(subItem.type)
            )
          } else {
            return (
              item.response.type && productsIncluded.includes(item.response.type)
            )
          }
        })
      }
      // Aplicar filtro de search se o valor de pesquisa não estiver vazio
      if (query !== '') {
        items = items?.filter((item) => {
          if ('values' in item.response) {
            return item.response.values.some((subItem) =>
              Object.values(subItem).some(
                (val) => val.toString().toLowerCase().includes(query.toLowerCase())
              )
            )
          } else {
            return (
              item.response.mounter.toLowerCase().includes(query.toLowerCase()) ||
              item.response.model.toLowerCase().includes(query.toLowerCase()) ||
              item.response.year.toLowerCase().includes(query.toLowerCase()) ||
              item.response.value.toString().includes(query.toLowerCase()) ||
              (item.response.somos_code?.toLowerCase() || '').includes(
                query.toLowerCase()
              ) ||
              (item.response.codigo_fipe?.toLowerCase() || '').includes(
                query.toLowerCase()
              ) ||
              item.user.email.toLowerCase().includes(query.toLowerCase()) ||
              item.user.first_name.toLowerCase().includes(query.toLowerCase()) ||
              item.user.last_name.toLowerCase().includes(query.toLowerCase())
            )
          }
        })
      }

      setDataSearch(items)

    },
    [data, productsIncluded]
  )

  async function getLogs() {
    setData(undefined)
    setDataSearch(undefined)
    setExcelData(undefined)
    setIndexSelected(0)

    let url = `table/history/?route=search/model/price/&initial_date=${values.initialDate}&final_date=${values.finalDate}`

    if (values.company !== 'all-companies') {
      url += `&company=${values.company}`
    }

    await api.get(url).then((response: any) => {
      setTimeout(() => {
        toast.dismiss()
        setData(response.data.content)
        setDataSearch(response.data.content)
        setExcelData(response.data.content.xlsx)
      }, 2000)
    }).catch((error) => {
      toast.error(DefaultMessage.ERROR, {
        position: toast.POSITION.TOP_RIGHT
      })
    })
  }

  const getCompanies = async () => {
    await api.get('company/list/').then((response: any) => {
      setCompanies(response.data.content)
    }).catch((error) => {
      toast.dismiss()
      toast.error(DefaultMessage.ERROR, {
        position: toast.POSITION.TOP_RIGHT
      })
    })
  }

  function getCookie(name: any) {
    let cookie: any = {};

    document.cookie.split(';').forEach(function (el) {
      let [k, v] = el.split('=');
      cookie[k.trim()] = v;
    })

    return cookie[name];

  }

  const downloadXLSX = async () => {

    if (!values.initialDate || !values.finalDate) {
      toast.dismiss()
      toast.warning("Favor definir a data inicial e final!");
    } else {

      const myCookie = getCookie("@Somos:access");

      let xlsxFileRoute = `${baseURL}/table/history-log-excel/?initial_date=${values.initialDate}&final_date=${values.finalDate}`;

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'multiplataform/form-data',
          Authorization: `Bearer ${myCookie}`
        }
      };

      fetch(xlsxFileRoute, options)
        .then(response => {
          return response.blob()
        })
        .then(blob => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'arquivo.xlsx');
          document.body.appendChild(link);
          link.click();
          link.parentNode?.removeChild(link);
        })
        .catch(err => console.error(err));
    }
  }

  useEffect(() => {
    getLogs()
    if (user?.is_staff || user?.is_admin) {
      getCompanies()
    }
  }, [])

  return (
    <Core withNavbar withBlueBar>
      <Grid
        container
        direction="column"
        gap={2}
        textAlign="center"
        paddingY={2}
      >
        <Typography variant="h5">
          <Chip label="RELATÓRIO DE CONSULTAS" style={{width: '300px', height: '3rem', borderRadius: '50rem'}} />
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid
            container
            gap={2}
            textAlign="center"
            justifyContent="space-around"
          >
            <Grid
              item
              minWidth={"203.069px"}
              width={'300px'}
            >
              <Typography variant="body1">
                Data inicial
              </Typography>
              <TextField
                type="date"
                id="initialDate"
                name="initialDate"
                value={values.initialDate}
                onChange={handleChange}
                style={{width: '300px'}}
                inputProps={{
                  max: todayDate
                }}
              />
            </Grid>
            <Grid
              item
              minWidth={"203.069px"}
              width={'300px'}
            >
              <Typography variant="body1">
                Data final
              </Typography>
              <TextField
                type="date"
                id="finalDate"
                name="finalDate"
                value={values.finalDate}
                onChange={handleChange}
                style={{width: '300px'}}
                inputProps={{
                  max: todayDate
                }}
              />
            </Grid>
            {
              user?.is_staff || user?.is_admin
                ? <Grid
                  item
                  minWidth={"203.069px"}
                  width={'300px'}
                >
                  <Typography variant="body1">
                    Empresa
                  </Typography>
                  <FormControl fullWidth>
                    <Select
                      labelId="companies-label"
                      id="companies-select"
                      name="company"
                      value={values.company}
                      label="Selecione uma empresa"
                      onChange={handleChange}
                      variant="outlined"
                    >
                      <MenuItem key="all-companies" value="all-companies">Todas as empresas</MenuItem>
                      {
                        companies?.map(
                          (item, index) => <MenuItem key={`comapny-${index}`} value={item.id}>
                            {item.social_reason}
                          </MenuItem>
                        )
                      }
                    </Select>
                  </FormControl>
                </Grid>
                : <></>
            }
          </Grid>
          <Grid
            container
            gap={2}
            textAlign="center"
            justifyContent="space-around"
            marginTop={2}
          >
            <Grid
              item
              width={'300px'}
            >
              <Button
                className="btn-inverse btn-inverse-2"
                variant="contained"
                size="large"
                color="secondary"
                type="submit"
                style={{width: '100%', borderRadius: '50rem', height: '2.5rem', color: 'white', background: '#00B9F2'}}
              >
                Consultar
              </Button>
            </Grid>
          </Grid>
        </form>
        <Grid
          paddingX={2}
        >
          <List
            sx={{ width: '100%', bgcolor: 'background.paper', marginTop: 2 }}
            component="div"
            subheader={
              <Typography
                variant="h6"
                color="text.primary"
              >
                <Grid
                  container
                  flexDirection={"column"}
                  alignContent={"center"}
                  margin={'2rem 0 2rem 0'}
                >
                  <Chip
                    avatar={<ContentPasteSearchIcon />}
                    label={<strong>{dataSearch?.length} resultados encontrados</strong>}
                    style={{ width: '300px', borderRadius: '2rem', height: '2.5rem' }}
                  />
                  <Button
                    style={{ width: '300px', color: 'white', borderRadius: '2rem', margin: '1rem 0 2rem 0', height: '2.5rem' }}
                    variant="contained"
                    onClick={downloadXLSX}
                    id="xlsxBtn"
                  >
                    {<MdSimCardDownload size={20} />} &nbsp; Baixar xlsx
                  </Button>
                </Grid>

                <Grid
                  container
                  gap={2}
                  textAlign="center"
                  justifyContent="center"
                  marginTop={3}
                >
                  {
                    user!.products.filter(item => item === '1').length > 0
                    && <Chip
                      style={{ minWidth: '150px' }}
                      avatar={<FaTractor color={productsIncluded.includes('1') ? 'white' : 'grey'} />}
                      label="Tratores"
                      onClick={() => includeProduct('1')}
                      color={productsIncluded.includes('1') ? 'info' : 'default'}
                    />
                  }
                  {
                    user!.products.filter(item => item === '2').length > 0
                    && <Chip
                      style={{ minWidth: '150px' }}
                      avatar={<FaHelicopter color={productsIncluded.includes('2') ? 'white' : 'grey'} />}
                      label="Helicópteros"
                      onClick={() => includeProduct('2')}
                      color={productsIncluded.includes('2') ? 'info' : 'default'}
                    />
                  }
                  {
                    user!.products.filter(item => item === '3').length > 0
                    && <Chip
                      style={{ minWidth: '150px' }}
                      avatar={<FaTrailer color={productsIncluded.includes('3') ? 'white' : 'grey'} />}
                      label="Semirreboques"
                      onClick={() => includeProduct('3')}
                      color={productsIncluded.includes('3') ? 'info' : 'default'}
                    />
                  }
                  {
                    user!.products.filter(item => item === '4').length > 0
                    && <Chip
                      style={{ minWidth: '150px' }}
                      avatar={<RiTruckFill color={productsIncluded.includes('4') ? 'white' : 'grey'} />}
                      label="Carrocerias"
                      onClick={() => includeProduct('4')}
                      color={productsIncluded.includes('4') ? 'info' : 'default'}
                    />
                  }
                  {
                    user!.products.filter(item => item === '5').length > 0
                    && <Chip
                      style={{ minWidth: '220px' }}
                      avatar={
                        <div style={{ marginRight: '1px', display: 'flex', width: 'max-content', height: 'max-content' }}>
                          <GiTruck size={28} color={productsIncluded.includes('5') ? 'white' : 'grey'} />
                          <GiBus size={28} color={productsIncluded.includes('5') ? 'white' : 'grey'} />
                        </div>
                      }
                      label="Caminhões e Ônibus"
                      onClick={() => includeProduct('5')}
                      color={productsIncluded.includes('5') ? 'info' : 'default'}
                    />
                  }
                  {
                    user!.products.filter(item => item === '6').length > 0
                    && <Chip
                      style={{ minWidth: 'max-content', padding: '0 .7rem' }}
                      avatar={
                        <div style={{ marginTop: '5px', opacity: productsIncluded.includes('6') ? '1' : '0.6' }}>
                          <img
                            width="20"
                            height="20"
                            src={
                              productsIncluded.includes('6')
                                ? whiteHarvest
                                : "https://img.icons8.com/material-rounded/96/harvester.png"
                            }
                            alt="harvester"
                          />
                        </div>
                      }
                      label="Colheitadeiras"
                      onClick={() => includeProduct('6')}
                      color={productsIncluded.includes('6') ? 'info' : 'default'}
                    />
                  }
                </Grid>
              </Typography>
            }
          >
            <Grid
              paddingX={width > 600 ? 10 : 5}
              marginY={2}
            >
              <TextField type="text" placeholder="Pesquisar" fullWidth onChange={handleChangeSearch} value={valueSearch} />
            </Grid>
            {
              dataSearch?.length !== undefined && dataSearch.length > 0
                ? dataSearch?.map((mainItem, index) => {
                  if ('values' in mainItem.response) {
                    if (mainItem.response.pricing) {
                      return <ListItemValues
                        index={index}
                        key={`${index}-datasearch`}
                        itemType={mainItem.response.values[0].type}
                        indexSelected={indexSelected}
                        plate={mainItem.response.pricing.plate}
                        chassi={mainItem.response.pricing.chassi}
                        email={mainItem.user.email}
                        created_at={mainItem.created_at}
                        items={mainItem.response.values}
                        setIndexSelected={setIndexSelected}
                        pricing={true}
                      />
                    } else {
                      return <ListItemValues
                        index={index}
                        key={`${index}-datasearch`}
                        itemType={mainItem.response.values[0].type}
                        indexSelected={indexSelected}
                        email={mainItem.user.email}
                        model={mainItem.response.values.length === 1 ? mainItem.response.values[0].model : undefined}
                        created_at={mainItem.created_at}
                        items={mainItem.response.values}
                        setIndexSelected={setIndexSelected}
                        pricing={false}
                      />
                    }
                  } else {
                    return <ListItemNotValues
                      index={index}
                      key={`${index}-datasearch`}
                      indexSelected={indexSelected}
                      email={mainItem.user.email}
                      item={mainItem.response}
                      created_at={mainItem.created_at}
                      setIndexSelected={setIndexSelected}
                    />
                  }
                })
                : dataSearch !== undefined
                  ? <Alert
                    variant="standard"
                    color="error"
                  >
                    Nada encontrado para o período e cliente informado
                  </Alert>
                  : <CircularProgress />
            }
          </List>
        </Grid>
      </Grid>
    </Core>
  )
}