import React, { useEffect, useState, useRef } from 'react';
import "./styles.css";

interface Translate {
    id: string;
    date: string;
    region: string;
    state: string;
    city: string;
    invoiced: string;
    fabricator: string;
    brand_model: string;
    year_fabrication: string;
    axis: string;
    product: string;
    type_vehicle: string;
    chassi: string;
    plate: string;
    month_registration: string;
    year_registration: string;
    somos_code: string;
    formatted_created_at: string;
    formatted_updated_at: string;
}

export const EditionModal = (props: any) => {

    const inputValues = [{}];
    const [chassiTitle, setChassiTitle] = useState<string>('');

    const modalRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        props.paramsEdit.map((item: Record<string, any>, index: number) =>
            Object.keys(item).map((el: any, subIndex: number) => {
                const value = inputValues[el] === undefined ? item[el] : inputValues[el];
                if (el === "chassi" && props.state) {
                    setChassiTitle(value);
                    return;
                }
            })
        )
    }, [props])

    const front: Translate = { id: "ID", date: 'Data', formatted_created_at: 'Criado em', formatted_updated_at: 'Atualizado em', region: 'Região', state: 'Estado', city: 'Cidade', invoiced: 'Faturamento', fabricator: 'Fabricante', brand_model: 'Marca', year_fabrication: 'Ano de fabricação', axis: 'Eixo', product: 'Produto', type_vehicle: 'Tipo de veículo', chassi: 'Chassi', plate: 'Placa', month_registration: 'Mês de emplacamento', year_registration: 'Ano de emplacamento', somos_code: 'Código Somos' }

    function getProperty<K extends keyof Translate>(front: Translate, chave: K): Translate[K] {
        return front[chave];
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        const handleClickOutside = (e: MouseEvent) => {
            if (modalRef.current && !modalRef.current.contains(e.target as Node)) {
                props.setModal(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [props]);

    return (
        props.state ?
            <div id='divEditModal' style={{ display: 'flex', position: 'fixed', top: '0%', height: '100vh', width: '100wh', background: 'rgba(1, 1, 1, 0.5)', backdropFilter: 'blur(2px)', zIndex: '1500', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                <div className="cardModal" id='cardModal' ref={modalRef}>
                    <div className='titleEdit'><h3 style={{ alignSelf: "end" }}>CHASSI: {chassiTitle}</h3></div>
                    <div className='inputsContainer'>
                        {props.paramsEdit.map((item: Record<string, any>, index: number) =>
                            Object.keys(item).map((el: any, subIndex: number) => {
                                const value = inputValues[el] === undefined ? item[el] : inputValues[el];
                                return (
                                    <div key={subIndex} className="visualizationCard">
                                        <label style={{ fontWeight: '600', color: 'rgba(44, 56, 74, 0.95)' }}>{`${getProperty(front, el)}: `} &nbsp; </label>
                                        <p style={{ marginBottom: '0', }}>{value}</p>
                                    </div>
                                );
                            })
                        )}
                    </div>
                    {
                        window.innerWidth < 990 ?
                            <div className="visualizationCard" style={{ border: '1px solid white', margin: '2rem 0 0', padding: '0 1.75rem' }}><button className='buttonSaveEdit' onClick={() => { props.setModal(false) }}>OK</button></div>
                            : <></>
                    }
                </div>
            </div>
            : <></>
    );
};
