import * as XLSX from "xlsx"
import Core from "../../Core"
import { useState } from "react"
import { useFormik } from 'formik'
import { toast } from 'react-toastify'
import colors from "../../../styles/colors"
import * as api from '../../../services/api'
import { FileUploader } from "react-drag-drop-files"
import JsonTable from "../../../components/JsonTable"
import CloudDoneIcon from '@mui/icons-material/CloudDone'
import DragAndDrop from "../../../components/DragAndDrop"
import { Button, Chip, FormControl, Grid, MenuItem, Select, TextField, Typography } from "@mui/material"

export default function NewTable(): JSX.Element {
  const [name, setName] = useState<string>('')
  const [preview, setPreview] = useState<any[] | undefined>(undefined)
  const [fileSelected, setFileSelected] = useState<File | undefined>(undefined)
  const [dragFile, setDragFile] = useState<boolean>(true)
  const [fileAdd, setFileAdd] = useState<boolean>(false)

  const { handleChange, handleSubmit, values, setFieldValue } = useFormik({
    initialValues: {
      productType: 'product-type',
      description: ''
    },
    onSubmit: uploadTable
  })

  const readExcel = async (file: any) => {
		const fileReader = await new FileReader()
		fileReader.readAsArrayBuffer(file)

		fileReader.onload = (e: any) => {
			const bufferArray = e?.target.result
			const wb = XLSX.read(bufferArray, {type: "buffer"})
			const wsname = wb.SheetNames[0]
			const ws = wb.Sheets[wsname]
			const data = (XLSX.utils.sheet_to_json(ws, {header: "A"}))
			const fileName = file.name
			setName(fileName)
			setPreview(data)
		}
	}

  const fileChanger = (value: File) => {
    setFieldValue('productType', 'product-type')
		setName(value.name)
		readExcel(value)
		setFileSelected(value)
    setFileAdd(true)
	}

  const reset = () => {
    setFieldValue('productType', 'product-type')
    setFieldValue('description', '')
    setName('')
    setPreview(undefined)
    setFileSelected(undefined)
    setDragFile(true)
    setFileAdd(false)
  }

  async function uploadTable() {
		if (values.productType === 'product-type') {
      return toast.error("Por favor, selecione um tipo de produto primeiro!", {
				position: toast.POSITION.TOP_RIGHT
			})
    }

		toast.loading("Aguarde...", {
			position: toast.POSITION.TOP_RIGHT,
		})

    const data = new FormData()
    data.append("file", fileSelected!)
    data.append("description", values.description)

		await api.post(
      `table/upload/${values.productType}/`, 
      data,
      'multipart/form-data; boundary=---011000010111000001101001'
    ).then((response: any) => {

      if (response.status !== 200 && response.status !== 201) {
        
        toast.dismiss()
        toast.warning(`${response.data.content.message}`, {
          position: toast.POSITION.TOP_RIGHT
        })
        reset()
      } else {

        toast.dismiss()
        toast.success(`PLANILHA CADASTRADA!`, {
          position: toast.POSITION.TOP_RIGHT
        })
        reset()
      }

			reset()
    
    }).catch((error) => {
      toast.dismiss()
      toast.error("Algo deu errado, verifique se o arquivo possui terminação '.xlsx'", {
				position: toast.POSITION.TOP_RIGHT
			})
      reset()
    })
	}

  return (
    <Core withBlueBar withNavbar path="/tables" label="Clique aqui para acessar as planilhas cadastradas">
      <Grid
        container
        direction="column"
        alignContent="center"
        alignItems="center"
        textAlign="center"
        paddingBottom={10}
      >
        {
          !preview ?
            fileAdd ?
             <FileUploader
                children={
                  <DragAndDrop dragFile={dragFile} progress={false} text="Arraste sua planilha aqui!"/>
                }
                hoverTitle={
                  <Typography variant="h5">
                    <CloudDoneIcon /> Tudo certo! Pode soltar :)
                  </Typography>
                }
                onDraggingStateChange={
                  (event: any) => setDragFile(event)
                }
                multiple={false}
                handleChange={fileChanger}
                name={"file"}
                types={['XLSX']}
              />
            :
            <FileUploader
                  children={
                    <DragAndDrop dragFile={dragFile} progress={true} text='Arraste sua planilha aqui!'/>
                  }
                  hoverTitle={
                    <Typography variant="h5">
                      <CloudDoneIcon /> Tudo certo! Pode soltar :)
                    </Typography>
                  }
                  onDraggingStateChange={
                    (event: any) => setDragFile(event)
                  }
                  multiple={false}
                  handleChange={fileChanger}
                  name={"file"}
                  types={['XLSX']}
                />
            : <>
                <Chip 
                  label={
                    <Typography variant="h6" sx={{ color: `${colors.WaterBlue}` }}>
                      <strong>Planilha adicionada:</strong> {name}
                    </Typography>
                  }
                />
                <JsonTable data={preview} />
                <form onSubmit={handleSubmit}>
                  <Grid
                    container
                    justifyContent="center"
                    paddingX={2}
                    gap={2}
                  >
                    <FormControl>
                      <Select
                        labelId="mounter-label"
                        id="mounter-select"
                        name="productType"
                        value={values.productType}
                        onChange={handleChange}
                        variant="outlined"
                      >
                        <MenuItem value="product-type">Selecione o tipo de produto</MenuItem>
                        <MenuItem value="1">Trator</MenuItem>
                        <MenuItem value="2">Helicóptero</MenuItem>
                        <MenuItem value="3">Semirreboque</MenuItem>
                        <MenuItem value="4">Carroceria</MenuItem>
                        <MenuItem value="6">Colheitadeira</MenuItem>
                      </Select>
                    </FormControl>
                    <TextField 
                      type="text" 
                      placeholder="Descrição (opcional)" 
                      fullWidth 
                      name="description"
                      onChange={handleChange} 
                    />
                    <Button
                      className="btn"
                      variant="contained"
                      size="large"
                      color="secondary"
                      type="button"
                      onClick={reset}
                    >
                      Trocar planilha
                    </Button>
                    <Button
                      className="btn-inverse"
                      variant="contained"
                      size="large"
                      color="secondary"
                      type="submit"
                    >
                      Enviar Planilha
                    </Button>
                  </Grid>
                </form>
              </>
        }
      </Grid>
    </Core>
  )
}