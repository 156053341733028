import FirstLoginPage from '../pages/FirstLoginPage'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

export default function FirstLoginRoutes(): JSX.Element {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='*' element={<FirstLoginPage />}/>
        <Route path='/' element={<FirstLoginPage />}/>
      </Routes>
    </BrowserRouter>
  )
}