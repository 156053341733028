import Core from "../Core"
import { useState } from "react"
import { useFormik } from 'formik'
import { toast } from 'react-toastify'
import * as api from '../../services/api'
import { FileUploader } from "react-drag-drop-files"
import DragAndDrop from "../../components/DragAndDrop"
import CloudDoneIcon from '@mui/icons-material/CloudDone'
import { DoubleChassiModal } from "../Pricing/DoubleChassiModal"
import { DragAndDropStyles } from "../../components/DragAndDrop/styles"
import { ResponsiveTypography } from "../../components/NewBlueBar/styles"
import { Button, CircularProgress, Grid, Typography } from "@mui/material"

export default function NewTable(): JSX.Element {

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [doubleChassi, setDoubleChassi] = useState<Array<string>>([''])

  const setModal = () => {
    setIsOpen(!isOpen);
  };

  const [name, setName] = useState<string>('')
  const [preview, setPreview] = useState<boolean>(false)
  const [fileSelected, setFileSelected] = useState<File | undefined>(undefined)
  const [dragFile, setDragFile] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [fileAdd, setFileAdd] = useState<boolean>(false)

  const { handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      productType: 'product-type',
      description: ''
    },
    onSubmit: uploadTable
  })

  const fileChanger = (value: File) => {
    setFieldValue('productType', 'product-type')
    setName(value.name)
    setPreview(true)
    setFileSelected(value)
    setFileAdd(true)
  }

  const reset = () => {
    setFieldValue('productType', 'product-type')
    setFieldValue('description', '')
    setName('')
    setPreview(false)
    setFileSelected(undefined)
    setDragFile(true)
    setFileAdd(false)
  }

  async function uploadTable() {

    const data = new FormData()
    data.append("file", fileSelected!);

    setLoading(true);

    await api.post(
      `/table/license/plates/upload/`,
      data,
      'multipart/form-data; boundary=---011000010111000001101001'
    ).then((response: any) => {

      setLoading(false);

      if (response.status !== 200 && response.status !== 201) {

        setErrorMessage(response.data.content.message);

        toast.dismiss()
        toast.warning(`${response.data.content.message}`, {
          position: toast.POSITION.TOP_RIGHT
        })

        if (response.data.content.duplicates) {
          setErrorMessage(response.data.content.message);
          setDoubleChassi(response.data.content.duplicates)
          setIsOpen(true)
        }

        reset()

      } else {

        if (response.status === 200 || response.status === 201) {

          if (response.data.content.duplicates) {
            setErrorMessage(response.data.content.message);
            setDoubleChassi(response.data.content.duplicates)
            setIsOpen(true)
          } else {
            toast.dismiss()
            toast.success(`PLANILHA CADASTRADA!`, {
              position: toast.POSITION.TOP_RIGHT
            })
          }
        }

        reset()

      }

    }).catch((error: Error) => {
      toast.dismiss()
      toast.error("Algo deu errado, verifique se o arquivo possui terminação '.xlsx'", {
        position: toast.POSITION.TOP_RIGHT
      })
    })
  }

  return (
    <div>
      <DoubleChassiModal chassis={doubleChassi} message={errorMessage} setModal={setModal} state={isOpen} />
      <Core withBlueBar withNavbar path="/pricing" label="Clique aqui para visualizar dados do precificador">
        <Grid
          container
          direction="column"
          alignContent="center"
          alignItems="center"
          textAlign="center"
          paddingBottom={10}
        >
          {
            !preview ?
              fileAdd ?
                <FileUploader
                  children={
                    <DragAndDrop dragFile={dragFile} heightStyled="10vh" text='Arraste seu precificador aqui!' />
                  }
                  hoverTitle={
                    <Typography variant="h5">
                      <CloudDoneIcon /> Tudo certo! Pode soltar :)
                    </Typography>
                  }
                  onDraggingStateChange={
                    (event: any) => setDragFile(event)
                  }
                  multiple={false}
                  handleChange={fileChanger}
                  name={"file"}
                  types={['XLSX']}
                />
                :
                <FileUploader
                  children={
                    <DragAndDrop dragFile={dragFile} progress={true} heightStyled="10vh" text='Arraste seu precificador aqui!' />
                  }
                  hoverTitle={
                    <Typography variant="h5">
                      <CloudDoneIcon /> Tudo certo! Pode soltar :)
                    </Typography>
                  }
                  onDraggingStateChange={
                    (event: any) => setDragFile(event)
                  }
                  multiple={false}
                  handleChange={fileChanger}
                  name={"file"}
                  types={['XLSX']}
                />
              : <>
                <DragAndDropStyles heightStyled="10vh" >
                  <ResponsiveTypography variant="h5">
                    <div style={{ display: 'flex', alignItems: 'center' }}>{name}</div>
                  </ResponsiveTypography>
                </DragAndDropStyles>
                <br />
                <form onSubmit={handleSubmit}>
                  <Grid
                    container
                    justifyContent="center"
                    paddingX={2}
                    gap={2}
                  >
                    {
                      loading
                        ? <CircularProgress />
                        :
                        <>
                          <Button
                            className="btn"
                            variant="contained"
                            size="large"
                            color="secondary"
                            type="button"
                            onClick={reset}
                          >
                            Trocar planilha
                          </Button>
                          <Button
                            className="btn-inverse"
                            variant="contained"
                            size="large"
                            color="secondary"
                            type="submit"
                          >
                            Enviar Planilha
                          </Button>
                        </>
                    }
                  </Grid>
                </form>
              </>
          }
        </Grid>
      </Core>
    </div>
  )
}