import React, { useEffect, useState, useRef } from 'react';
//@ts-ignore
import styles from "./styles.module.css";

export const ErrorModal = (props: any) => {

    const modalRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (e: MouseEvent) => {
            if (modalRef.current && !modalRef.current.contains(e.target as Node)) {
                props.setModal(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [props]);

    return (
        props.state ?
            <div id={styles.divEditModal} style={{ display: 'flex', position: 'fixed', top: '0%', height: '100vh', width: '100%', background: 'rgba(1, 1, 1, 0.5)', backdropFilter: 'blur(2px)', zIndex: '1500', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                    <div className={styles.cardModal} id={styles.cardModal} ref={modalRef}>
                    <div className={styles.titleEdit}><h3 style={{ alignSelf: "end" }}>ERRO NO PROCESSAMENTO</h3></div>
                    <div className={styles.inputsContainer}>
                        {props.errorMessage}
                    </div>
                    {
                        window.innerWidth < 990 ?
                            <div className={styles.visualizationCard} style={{ border: '1px solid white', margin: '2rem 0 0', padding: '0 1.75rem' }}><button className='buttonSaveEdit' onClick={() => { props.setModal(false) }}>OK</button></div>
                            : <></>
                    }
                </div>
            </div>
            : <></>
    );
};