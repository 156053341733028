import colors from "./colors"
import { createTheme } from "@mui/material"

export const LightMode = {
  primary: {
    main: colors.LoginBlue
  },
  secondary: {
    main: colors.IceWhite
  },
  info: {
    main: colors.WaterBlue
  },
  success: {
    main: colors.OceanLightBlue
  },
  warning: {
    main: colors.MoonYellow
  },
  error: {
    main: colors.ChillyWillyRed
  },
  background: {
    default: colors.DarkerGray,
    paper: colors.IceWhite
  },
  text: {
    primary: colors.DarkerGray,
    secondary: colors.IceWhite,
    disabled: colors.FogGray
  },
  divider: colors.FogGray,
  action: {
    active: colors.EskimoGray,
    hover: colors.LowBlue,
    disabledBackground: colors.FogGray
  }
}

export const LightTheme = createTheme({
  components: {
    MuiInput: {
      styleOverrides: {
        input: {
          "&::placeholder": {
            color: {
              main: colors.IceWhite
            }
          }
        }
      }
    },
  },
  palette: {
    primary: {
      50: colors.OceanDarkBlue,
      100: colors.OceanDarkBlue,
      200: colors.OceanDarkBlue,
      300: colors.OceanDarkBlue,
      400: colors.OceanDarkBlue,
      500: colors.OceanDarkBlue,
      600: colors.OceanDarkBlue,
      700: colors.OceanDarkBlue,
      800: colors.OceanDarkBlue,
      900: colors.OceanDarkBlue,
      A100: colors.OceanDarkBlue,
      A200: colors.OceanDarkBlue,
      A400: colors.OceanDarkBlue,
      A700: colors.OceanDarkBlue,
    },
    secondary: {
      50: colors.EskimoGray,
      100: colors.EskimoGray,
      200: colors.EskimoGray,
      300: colors.EskimoGray,
      400: colors.EskimoGray,
      500: colors.EskimoGray,
      600: colors.EskimoGray,
      700: colors.EskimoGray,
      800: colors.EskimoGray,
      900: colors.EskimoGray,
      A100: colors.EskimoGray,
      A200: colors.EskimoGray,
      A400: colors.EskimoGray,
      A700: colors.EskimoGray,
    },
    info: {
      50: colors.WaterBlue,
      100: colors.WaterBlue,
      200: colors.WaterBlue,
      300: colors.WaterBlue,
      400: colors.WaterBlue,
      500: colors.WaterBlue,
      600: colors.WaterBlue,
      700: colors.WaterBlue,
      800: colors.WaterBlue,
      900: colors.WaterBlue,
      A100: colors.WaterBlue,
      A200: colors.WaterBlue,
      A400: colors.WaterBlue,
      A700: colors.WaterBlue,
    },
    success: {
      50: colors.OceanLightBlue,
      100: colors.OceanLightBlue,
      200: colors.OceanLightBlue,
      300: colors.OceanLightBlue,
      400: colors.OceanLightBlue,
      500: colors.OceanLightBlue,
      600: colors.OceanLightBlue,
      700: colors.OceanLightBlue,
      800: colors.OceanLightBlue,
      900: colors.OceanLightBlue,
      A100: colors.OceanLightBlue,
      A200: colors.OceanLightBlue,
      A400: colors.OceanLightBlue,
      A700: colors.OceanLightBlue,
    },
    warning: {
      50: colors.MoonYellow,
      100: colors.MoonYellow,
      200: colors.MoonYellow,
      300: colors.MoonYellow,
      400: colors.MoonYellow,
      500: colors.MoonYellow,
      600: colors.MoonYellow,
      700: colors.MoonYellow,
      800: colors.MoonYellow,
      900: colors.MoonYellow,
      A100: colors.MoonYellow,
      A200: colors.MoonYellow,
      A400: colors.MoonYellow,
      A700: colors.MoonYellow,
    },
    error: {
      50: colors.ChillyWillyRed,
      100: colors.ChillyWillyRed,
      200: colors.ChillyWillyRed,
      300: colors.ChillyWillyRed,
      400: colors.ChillyWillyRed,
      500: colors.ChillyWillyRed,
      600: colors.ChillyWillyRed,
      700: colors.ChillyWillyRed,
      800: colors.ChillyWillyRed,
      900: colors.ChillyWillyRed,
      A100: colors.ChillyWillyRed,
      A200: colors.ChillyWillyRed,
      A400: colors.ChillyWillyRed,
      A700: colors.ChillyWillyRed,
    },
    background: {
      default: colors.DarkerGray,
      paper: colors.IceWhite,
    },
    text: {
      primary: colors.DarkerGray,
      secondary: colors.IceWhite,
    },
    action: {
      active: colors.EskimoGray,
    },
  }
})