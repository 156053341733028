import Core from "../../Core";
import { useState } from "react";
import { toast } from "react-toastify";
import colors from "../../../styles/colors";
import { RiTruckFill } from "react-icons/ri";
import * as api from "../../../services/api";
import { IoMdBusiness } from 'react-icons/io';
import { Link, useNavigate } from "react-router-dom";
//@ts-ignore
import styles from './switchButton.module.css';
import { TiBusinessCard } from 'react-icons/ti';
import { GiTruck, GiBus } from "react-icons/gi";
import { cookies } from "../../../hooks/useCookies";
import useWindowSize from "../../../hooks/useWindowSize";
import { Button, Chip, Grid, Typography } from "@mui/material";
import InputSignUpMemorized from "../../../components/InputSignUp";
import { FaHelicopter, FaTractor, FaTrailer } from "react-icons/fa";
import SwitchButton from "../../../components/SwitchButton/SwitchButton";
import { MdOutlineAlternateEmail, MdLocationCity } from 'react-icons/md';
import { BsFillHouseFill, BsFillPersonFill, BsFillPersonLinesFill } from 'react-icons/bs';

export default function SignUp() {

    const navigate = useNavigate()
    const { width, height } = useWindowSize()

    const widthChip = width > 900 && width < 1386 ? '95%' : width < 627 ? '95%' : '46%'

    const [changeButton, setChangeButton] = useState(1)
    const [disabledButton, setDisabledButton] = useState<boolean>(false)
    const [chipValues, setChipValues] = useState<Array<string>>([])

    const somosLogo = require("../../../images/horizontalLogo.png");
    const colheitadeiraBranca = require("../../../images/colheitadeiraBranca.png");

    const iconsProps = {
        color: "white",
        size: width <= 768 ? 18 : 28
    }

    async function signUp(evento: any) {

        const formData = new FormData(evento);
        let formProps = Object.fromEntries(formData);

        delete formProps['switchPlan']

        if (chipValues.length === 0) {
            toast.dismiss()
            return toast.error('Selecione ao menos um produto', { position: toast.POSITION.TOP_RIGHT })
        }

        const data = { ...formProps, "products": chipValues }

        if (changeButton === 1) {
            await api.post('user/create_normal_user/', data)
                .then((response: any) => {
                    if (response?.status === 201) {
                        cookies.set('@Somos:toastSignUp', true, { path: '/', sameSite: 'lax' })
                        toast.dismiss()
                        navigate('/')
                    } else if (response.status === 403) {
                        toast.dismiss()
                        toast.error(response?.data?.content, { position: toast.POSITION.TOP_RIGHT })
                    } else {
                        toast.dismiss()
                        toast.error('ocorreu um erro ao tentar criar o usuário, tente novamente mais tarde', { position: toast.POSITION.TOP_RIGHT })
                    }
                })
                .catch(error => {
                    toast.error('ocorreu um erro ao tentar criar o usuário, tente novamente mais tarde', { position: toast.POSITION.TOP_RIGHT })
                })
        } else {
            await api.post('user/create_user_with_company/', data)
                .then((response: any) => {
                    if (response?.status === 201) {
                        cookies.set('@Somos:toastSignUp', true, { path: '/', sameSite: 'lax' })
                        toast.dismiss()
                        navigate('/')
                    } else if (response.status === 403) {
                        toast.dismiss()
                        toast.error(response?.data?.content, { position: toast.POSITION.TOP_RIGHT })
                    } else {
                        toast.dismiss()
                        toast.error('ocorreu um erro ao tentar criar o usuário, tente novamente mais tarde', { position: toast.POSITION.TOP_RIGHT })
                    }
                })
                .catch(error => {
                    toast.error('ocorreu um erro ao tentar criar o usuário, tente novamente mais tarde', { position: toast.POSITION.TOP_RIGHT })
                })
        }
    }

    async function handleSignUp(evento: any) {
        toast.loading('Aguarde')
        setDisabledButton(true)
        await signUp(evento)
        setDisabledButton(false)
    }

    function addProduct(product: string) {
        let products = [...chipValues]
        if (chipValues.includes(product)) {
            products = products.filter(item => item !== product)
            setChipValues(products)
        } else {
            setChipValues([...products, product])
        }
    }

    return (
        <Core>
            <Grid
                container
                height="100vh"
                flexDirection={width > 900 ? "row" : 'column'}
                gap={2}
                textAlign="center"
                justifyContent="flex-start"
                alignItems={"center"}
                sx={{ backgroundColor: `${colors.DarkerGray}`, paddingLeft: '0px', paddingRight: width > 900 ? '16px' : '0' }}
                wrap="nowrap"
                paddingX={2}
            >
                {width > 900 ? <> </> : <div className={styles.imageBack2} style={{ height: '100vh', width: '100%', position: 'fixed', zIndex: '0' }} />}
                {
                    width > 900
                        ? <Grid
                            height={'100%'}
                            width={'50%'}
                            display={'flex'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            item
                        >
                            <div style={{ height: '100%', width: '100%' }}>
                                <div className={styles.imageBack} style={{ width: '-webkit-fill-available', height: '100%', top: '0', zIndex: '0', position: 'fixed' }} />
                            </div>
                            <img src={somosLogo} width={'100%'} height="auto" alt="Logo" style={{ zIndex: '2', position: "fixed", width: '40%' }} />
                        </Grid>
                        : <Grid
                            container
                            height={'max-content'}
                            display={'flex'}
                            justifyContent={'center'}
                            width={width > 900 ? '50%' : '80%'}
                            flexDirection="row"
                            alignItems="center"
                        >

                            <img src={somosLogo} width={'100%'} height="auto" alt="Logo" style={{ zIndex: '2', marginTop: width <= 901 ? "50px" : "0" }}
                            />
                        </Grid>
                }
                <form
                    onSubmit={(e) => {
                        e.preventDefault()
                        handleSignUp(e.target)
                    }
                    }
                    style={
                        {
                            width: width > 900 ? '50%' : '80%',
                            height: width! > 901 ? '100%' : 'max-content',
                            display: height! < 697 ? 'grid' : width >= 900 && height! < 975 ? 'grid' : 'block'
                        }
                    }
                >
                    <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column', gap: '30px', justifyContent: height! < 697 && changeButton === 2 ? 'normal' : 'center' }}>
                        <SwitchButton firstLabel="Usuário" secondLabel="Empresa" />
                        <div style={{ width: width > 900 ? "60%" : "90%", height: "max-content", gap: '25px', gridTemplateColumns: `repeat( auto-fit, minmax(${changeButton === 1 ? '100%' : width > 1100 ? '355px' : width > 767 ? '182px' : '220px'}, 1fr))`, display: 'grid' }}>
                            <InputSignUpMemorized
                                name="first_name"
                                value=''
                                onKeyUp={undefined}
                                type="text"
                                fullWidth={false}
                                style={undefined}
                                placeholder="Nome"
                                onChange={undefined}
                                className="clientInput"
                                icon={<BsFillPersonFill {...iconsProps} />}
                            />

                            <InputSignUpMemorized
                                name="last_name"
                                value=""
                                onKeyUp={undefined}
                                type="text"
                                fullWidth={false}
                                style={undefined}
                                onChange={undefined}
                                className="clientInput"
                                placeholder="Sobrenome"
                                icon={<BsFillPersonLinesFill {...iconsProps} />}
                            />
                            <InputSignUpMemorized
                                name="email"
                                value=""
                                onKeyUp={undefined}
                                type="email"
                                fullWidth={false}
                                style={undefined}
                                onChange={undefined}
                                placeholder="E-mail"
                                className="clientInput"
                                icon={<MdOutlineAlternateEmail {...iconsProps} />}
                            />
                            {changeButton === 2 &&
                                <>
                                    <InputSignUpMemorized
                                        name="cnpj"
                                        value=""
                                        onKeyUp={undefined}
                                        type="number"
                                        style={undefined}
                                        fullWidth={false}
                                        placeholder="CNPJ"
                                        onChange={undefined}
                                        className="clientInput"
                                        icon={<TiBusinessCard {...iconsProps} />}
                                    />
                                    <InputSignUpMemorized
                                        name="cep"
                                        value=""
                                        onKeyUp={undefined}
                                        type="number"
                                        style={undefined}
                                        fullWidth={false}
                                        placeholder="CEP"
                                        onChange={undefined}
                                        className="clientInput"
                                        icon={<BsFillHouseFill {...iconsProps} />}
                                    />
                                    <InputSignUpMemorized
                                        name="social_reason"
                                        value=""
                                        onKeyUp={undefined}
                                        type="text"
                                        fullWidth={false}
                                        style={undefined}
                                        onChange={undefined}
                                        className="clientInput"
                                        placeholder="Razão Social"
                                        icon={<IoMdBusiness {...iconsProps} />}
                                    />
                                    <InputSignUpMemorized
                                        name="fantasy_name"
                                        value=""
                                        onKeyUp={undefined}
                                        type="text"
                                        fullWidth={false}
                                        style={undefined}
                                        onChange={undefined}
                                        className="clientInput"
                                        placeholder="Nome Fantasia"
                                        icon={<MdLocationCity {...iconsProps} />}
                                    />
                                    <InputSignUpMemorized
                                        name="email_company"
                                        value=""
                                        onKeyUp={undefined}
                                        type="email"
                                        fullWidth={false}
                                        style={undefined}
                                        onChange={undefined}
                                        className="clientInput"
                                        placeholder="E-mail da empresa"
                                        icon={<MdOutlineAlternateEmail {...iconsProps} />}
                                    />
                                    {/* <InputSignUpMemorized
                                        name=""
                                        value=""
                                        onKeyUp={undefined}
                                        type="number"
                                        fullWidth={false}
                                        style={undefined}
                                        onChange={undefined}
                                        placeholder="Celular"
                                        className="clientInput"
                                        icon={<MdSmartphone {...iconsProps} />}
                                    /> */}
                                </>
                            }
                        </div>
                        <div style={{ zIndex: '2', width: width <= 900 ? '80%' : '60%', padding: '10px 0px 30px 0px', borderRadius: '30px', border: `solid 1px rgb(101 128 140)` }}>
                            <Typography variant={'body1'} marginY={2} color="primary" position={"relative"}>
                                <strong>
                                    Selecione os produtos do seu interesse:
                                </strong>
                            </Typography>
                            <div style={{ display: 'flex', flexDirection: 'row', rowGap: '20px', flexWrap: 'wrap', justifyContent: 'center' }}>
                                <Chip
                                    sx={{ width: widthChip, marginX: 1, color: chipValues.filter(item => item === "1").length > 0 ? colors.IceWhite : colors.DarkerGray }}
                                    icon={<FaTractor />}
                                    label="Trator"
                                    color={chipValues.filter(item => item === "1").length > 0 ? "primary" : "secondary"}
                                    onClick={() => {
                                        addProduct("1")
                                    }}
                                />
                                <Chip
                                    sx={{ width: widthChip, marginX: 1, color: chipValues.filter(item => item === "2").length > 0 ? colors.IceWhite : colors.DarkerGray }}
                                    icon={<FaHelicopter />}
                                    label="Helicóptero"
                                    color={chipValues.filter(item => item === "2").length > 0 ? "primary" : "secondary"}
                                    onClick={() => {
                                        addProduct("2")
                                    }}
                                />
                                <Chip
                                    sx={{ width: widthChip, marginX: 1, color: chipValues.filter(item => item === "3").length > 0 ? colors.IceWhite : colors.DarkerGray }}
                                    icon={<FaTrailer />}
                                    label="Semirreboque"
                                    color={chipValues.filter(item => item === "3").length > 0 ? "primary" : "secondary"}
                                    onClick={() => {
                                        addProduct("3")
                                    }}
                                />
                                <Chip
                                    sx={{ width: widthChip, marginX: 1, color: chipValues.filter(item => item === "4").length > 0 ? colors.IceWhite : colors.DarkerGray }}
                                    icon={<RiTruckFill />}
                                    label="Carroceria"
                                    color={chipValues.filter(item => item === "4").length > 0 ? "primary" : "secondary"}
                                    onClick={() => {
                                        addProduct("4")
                                    }}
                                />
                                <Chip
                                    sx={{ width: widthChip, marginX: 1, color: chipValues.filter(item => item === "5").length > 0 ? colors.IceWhite : colors.DarkerGray }}
                                    icon={<div style={{ display: 'flex', flexDirection: 'column' }}><GiTruck /><GiBus /></div>}
                                    label="Caminhão e Ônibus"
                                    color={chipValues.filter(item => item === "5").length > 0 ? "primary" : "secondary"}
                                    onClick={() => {
                                        addProduct("5")
                                    }}
                                />
                                <Chip
                                    sx={{ width: widthChip, marginX: 1, color: chipValues.filter(item => item === "6").length > 0 ? colors.IceWhite : colors.DarkerGray }}
                                    icon={<div style={{ display: 'flex', flexDirection: 'column' }}><img width="12" height="12" src={chipValues.filter(item => item === "6").length > 0 ? colheitadeiraBranca : "https://img.icons8.com/material-rounded/96/harvester.png"} alt="harvester" /></div>}
                                    label="Colheitadeira"
                                    color={chipValues.filter(item => item === "6").length > 0 ? "primary" : "secondary"}
                                    onClick={() => {
                                        addProduct("6")
                                    }}
                                />
                            </div>
                        </div>
                        <Typography color="secondary" style={{ margin: '0' }} zIndex={1} marginY={4}>
                            Fazer login? <Link to={`/`}>Clique aqui</Link>
                        </Typography>
                        <Button
                            size="large"
                            type="submit"
                            color="secondary"
                            variant="contained"
                            disabled={disabledButton}
                            className={`btnSignUp ${styles.buttonSubmit}`}
                            style={{ marginBottom: height! < 697 ? '20px' : '0' }}
                        >
                            Cadastrar
                        </Button>
                        <div style={{ height: '20px' }} />
                    </div>
                </form>
                {width >= 900 && height! < 975 ? <div style={{ height: '20px' }}></div> : <></>}
                <div id="switch" style={{ display: 'none' }} onClick={() => { setChangeButton(changeButton === 1 ? 2 : 1) }}></div>
            </Grid>
        </Core>
    )
}