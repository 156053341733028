import styled from "styled-components"
import colors from "../../styles/colors"
import { Typography } from "@mui/material"

interface DragAndDropStylesProps {
  heightStyled: string;
}

export const DragAndDropStyles = styled.div<DragAndDropStylesProps>`
  border: 4px dashed ${colors.EskimoGray};
  width: 70vw;
  height: ${ props => props.heightStyled};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 50px;
  background-color: ${colors.IceWhite};
  color: ${colors.EskimoGray};

  :hover {
    background-color: ${colors.LowBlue};
  }

  @media (max-width: 350px) {

    font-size: '0.5rem'
    
  }

  @media (max-width: 420px){
    width: 90vw;
  }
`

export const ResponsiveTypography = styled(Typography)`
&& {
  @media (max-width: 350px) {
    font-size: 14px; /* Defina o tamanho de fonte adequado para telas menores */
  }
  /* Adicione outras media queries conforme necessário para diferentes tamanhos de tela */
}
`