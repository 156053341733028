import styled from "styled-components"
import colors from "../../styles/colors"

export const JsonTableStyles = styled.div`
  
  display: flex;
  overflow-x: scroll;
  white-space: nowrap;
  width: 90%;
  max-height: 50vh;
  border: none;
  text-align: center;
	margin: 20px 0;
	border: ${colors.LowBlue} 1px solid;
	padding: 10px;

	::-webkit-scrollbar {
		display: block;
	}

	::-webkit-scrollbar-thumb {
		border-radius: 2px;       
		border: 1px solid #c2c2c2;
  		background-color: #c2c2c2;
	}

	td {
		border:  none;
		padding: 2px 10px;
	}

	tr:nth-child(even) {
		background-color: ${colors.LowBlue};
	}
  
	tr:hover {
		background-color: ${colors.FogGray};
		color: ${colors.IceWhite};
	}
    
	th {
		padding: 4px 10px;
		border: none;
		background-color: ${colors.DarkerGray};
		color: ${colors.IceWhite};
	}
`