import Core from "../../Core"
import { TableProps } from "../types"
import { toast } from "react-toastify"
import * as api from "../../../services/api"
import { Fragment, useEffect, useState } from "react"
import { DefaultMessage } from "../../../utils/defaultMessages"
import AdjustRoundedIcon from '@mui/icons-material/AdjustRounded'
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded'
import { FaHelicopter, FaTractor, FaTrailer } from 'react-icons/fa'
import RadioButtonUncheckedRoundedIcon from '@mui/icons-material/RadioButtonUncheckedRounded'
import { Avatar, Button, Chip, Dialog, DialogActions, DialogContent, Divider, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material"
import { RiTruckFill } from "react-icons/ri"

export default function Tables(): JSX.Element {
  const [productSelected, setProductSelected] = useState<string>('tractors')
  const [data, setData] = useState<TableProps[]>()
  const [productToDelete, setProductToDelete] = useState<string>()
  const [hashToDelete, setHashToDelete] = useState<string>()
  const [nameToDelete, setNameToDelete] = useState<string>()

  const getData = async (param: string) => {
    await api.get(`table/retrieve/${param}/`).then((response: any) => {
      setData(response.data.content)
    }).catch((error) => {
      toast.error(DefaultMessage.ERROR, {
        position: toast.POSITION.TOP_RIGHT
      })
    })
  }

  function handleOpenDialog(hash: string, product: string, name: string) {
    setProductToDelete(product)
    setHashToDelete(hash)
    setNameToDelete(name)
  }

  const deleteTable = async () => {
    toast.loading(
      "Aguarde...", {
      position: toast.POSITION.TOP_RIGHT,
    }
    )
    await api.destroy(`table/delete/${productToDelete}/${hashToDelete}/`).then((response: any) => {
      toast.dismiss()
      if (response.status === 204) {

        getData(productToDelete!)
        setHashToDelete(undefined)
        setProductToDelete(undefined)
        setNameToDelete(undefined)

        toast.success("Planilha excluída com sucesso!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000
        })

      } else {

        toast.error(DefaultMessage.ERROR, {
          position: toast.POSITION.TOP_RIGHT
        })

      }
    }).catch((error) => {
      toast.dismiss()
      toast.error(DefaultMessage.ERROR, {
        position: toast.POSITION.TOP_RIGHT
      })
    })
  }

  const DialogComponent = (
    <Dialog
      open={hashToDelete !== undefined && productSelected !== undefined}
      onClose={() => {
        setHashToDelete(undefined)
        setProductToDelete(undefined)
        setNameToDelete(undefined)
      }}
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <Typography color="black">
          Tem certeza que deseja desativar a planilha {nameToDelete}?
        </Typography>
        <Typography color="red">
          Essa operação não poderá ser desfeita
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setHashToDelete(undefined)
            setProductToDelete(undefined)
            setNameToDelete(undefined)
          }}
          color="primary"
        >
          NÃO
        </Button>
        <Button
          onClick={() => {
            deleteTable()
            setHashToDelete(undefined)
            setProductToDelete(undefined)
            setNameToDelete(undefined)
          }}
        >
          SIM
        </Button>
      </DialogActions>
    </Dialog>
  )

  useEffect(() => {
    getData('1')
  }, [])

  return (
    <Core withNavbar withBlueBar path="/new-table" label="Clique aqui para voltar a cadastrar planilhas">
      {DialogComponent}
      <Grid
        container
        direction="column"
        alignContent="center"
        alignItems="center"
        textAlign="center"
        paddingBottom={10}
      >
        <Chip label="Selecione um produto para filtrar a listagem" />
        <Grid
          container
          textAlign="center"
          justifyContent="center"
          paddingY={4}
          gap={2}
        >
          <Chip
            label="Tratores"
            color={productSelected === 'tractors' ? "primary" : "default"}
            variant={productSelected === 'tractors' ? "outlined" : "filled"}
            onClick={() => {
              setProductSelected('tractors')
              getData('1')
            }}
            icon={productSelected === 'tractors' ? <AdjustRoundedIcon /> : <RadioButtonUncheckedRoundedIcon />}
          />
          <Chip
            label="Helicopteros"
            color={productSelected === 'helicopters' ? "primary" : "default"}
            variant={productSelected === 'helicopters' ? "outlined" : "filled"}
            onClick={() => {
              setProductSelected('helicopters')
              getData('2')
            }}
            icon={productSelected === 'helicopters' ? <AdjustRoundedIcon /> : <RadioButtonUncheckedRoundedIcon />}
          />
          <Chip
            label="Semirreboques"
            color={productSelected === 'trailers' ? "primary" : "default"}
            variant={productSelected === 'trailers' ? "outlined" : "filled"}
            onClick={() => {
              setProductSelected('trailers')
              getData('3')
            }}
            icon={productSelected === 'trailers' ? <AdjustRoundedIcon /> : <RadioButtonUncheckedRoundedIcon />}
          />
          <Chip
            label="Carrocerias"
            color={productSelected === 'bodywork' ? "primary" : "default"}
            variant={productSelected === 'bodywork' ? "outlined" : "filled"}
            onClick={() => {
              setProductSelected('bodywork')
              getData('4')
            }}
            icon={productSelected === 'bodywork' ? <AdjustRoundedIcon /> : <RadioButtonUncheckedRoundedIcon />}
          />
          <Chip
            label="Colheitadeira"
            color={productSelected === 'Colheitadeira' ? "primary" : "default"}
            variant={productSelected === 'Colheitadeira' ? "outlined" : "filled"}
            onClick={() => {
              setProductSelected('Colheitadeira')
              getData('6')
            }}
            icon={productSelected === 'Colheitadeira' ? <AdjustRoundedIcon /> : <RadioButtonUncheckedRoundedIcon />}
          />
        </Grid>
        <List sx={{ width: '100%', maxWidth: 900, bgcolor: 'background.paper' }}>
          {data?.map((item, index) => (
            <Fragment key={`file-item-${index}`}>
              <ListItem
                alignItems="flex-start"
                secondaryAction={
                  item.is_active && (
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => handleOpenDialog(item.hash, item.type, item.file_name)}
                    >
                      <DeleteRoundedIcon />
                    </IconButton>
                  )
                }
              >
                <ListItemAvatar>
                  <Avatar>
                    {item.type === "1" && <FaTractor />}
                    {item.type === "2" && <FaHelicopter />}
                    {item.type === "3" && <FaTrailer />}
                    {item.type === "4" && <RiTruckFill />}
                    {item.type === "6" && <img width="20" height="20" src="https://img.icons8.com/material-rounded/96/harvester.png" alt="harvester" />}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={item.file_name}
                  secondary={
                    <span>
                      <Typography variant="body1" color="text.primary" component="span">
                        {item.note}
                      </Typography>
                      <br />
                      <Typography variant="body1" color="text.primary" component="span">
                        {`Cadastrada no dia ${item.created_at}`}
                      </Typography>
                      <br />
                      <Chip
                        label={item.is_active ? 'Planilha ativa' : 'Planilha inativa'}
                        color={item.is_active ? 'success' : 'error'}
                        component="span"
                      />
                    </span>
                  }
                />
              </ListItem>
              <Divider variant="inset" component="li" />
            </Fragment>
          ))}
        </List>
      </Grid>
    </Core>
  )
}