import React from "react"
import Core from "../Core"
import { useFormik } from "formik"
import { toast } from "react-toastify"
//@ts-ignore
import styles from './styles.module.css'
import colors from "../../styles/colors"
import * as api from "../../services/api"
import { GiPadlock } from "react-icons/gi"
import { useAuth } from "../../hooks/useAuth"
import { useNavigate } from "react-router-dom"
import useWindowSize from "../../hooks/useWindowSize"
import { Button, Grid, Typography } from "@mui/material"
import InputSignUpMemorized from "../../components/InputSignUp"

export default function FirstLoginPage(): JSX.Element {
  const navigate = useNavigate()
  const { width } = useWindowSize()
  const { user, Logout, setToastLogin } = useAuth()

  const { handleChange, handleSubmit, values } = useFormik({
    initialValues: {
      password: '',
      confirm_password: ''
    },
    onSubmit: resetRandomPassword
  })


  async function resetRandomPassword() {
    const idCookie = user?.id

    if (values.password === values.confirm_password) {
      toast.loading(
        "Aguarde...", {
        position: toast.POSITION.TOP_RIGHT,
      }
      )

      await api.put(`user/login/${idCookie}/`, values).then((response:any) => {
        if(response?.status === 200) {
          Logout(true)
          setToastLogin(true)
          setTimeout(() => {
            navigate("/")
          }, 1000)
        } else {
          toast.dismiss()
          toast.error("Verifique se a senha informada cumpre todos os requesitos e tente novamente!")
        }
      }).catch((error) => {
        toast.dismiss()
        toast.error("Verifique se sua senha respeita a regra informada!", {
          position: toast.POSITION.TOP_RIGHT
        })
      })
    } else {
      toast.error("As senhas não são iguais", {
        position: toast.POSITION.TOP_RIGHT
      })
    }
  }

  const somosLogo = require("../../images/horizontalLogo.png")

  const iconsProps = {
    color: "white",
    size: width <= 768 ? 18 : 28
  }

  return (
    <Core>
      <Grid
        container
        height="100vh"
        alignContent="center"
        alignItems="center"
        textAlign="center"
        justifyContent="center"
        justifyItems="center"
        sx={{ backgroundColor: `${colors.DarkerGray}` }}
      >
        {width > 900 ? <> </> : <div className={styles.imageBackMobile} style={{ height: '100vh', width: '100%', position: 'fixed', zIndex: '0' }} />}
        {
          width > 900
            ? <Grid
              height={'100%'}
              width={'50%'}
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
              item
            >
              <div style={{ height: '100%', width: '100%' }}>
                <div className={styles.imageBack} style={{ width: '-webkit-fill-available', height: '100%', top: '0', zIndex: '0', position: 'fixed' }} />
                {/* <div style={{position: 'absolute', height: '100%', width: '-webkit-fill-available', top: '0', zIndex: '1', backgroundColor: '#000000d4' }}/> */}
              </div>
              <img src={somosLogo} width={'100%'} height="auto" alt="Logo" style={{ zIndex: '2', position: "fixed", width: '40%' }} />
            </Grid>
            : <Grid
              container
              height={'max-content'}
              display={'flex'}
              justifyContent={'center'}
              width={width > 900 ? '50%' : '80%'}
              flexDirection="row"
              alignItems="center"
              marginBottom={2}
            >

              <img src={somosLogo} width={'100%'} height="auto" alt="Logo" style={{ zIndex: '2', marginTop: width <= 901 ? "50px" : "0" }} />
            </Grid>
        }
          <form onSubmit={handleSubmit} style={{width: width >= 900 ? '50%' : '100%', zIndex: 3, alignItems: 'center', display: 'flex', justifyContent: 'center', gap: '30px', flexDirection: 'column'}}>
              <Typography variant={width > 720 ? 'h4' : 'h6'} color="primary">
                <strong>ESTE É SEU PRIMEIRO ACESSO</strong>
              </Typography>
              <div>
                <Typography variant="body1" color="secondary">
                  <strong>Defina uma nova senha para continuar</strong>
                </Typography>
                <Typography variant="body1" color="secondary">
                  Sua senha deve conter no mínimo 8 caracteres, sendo:
                </Typography>
                <Typography className={styles.zeroPadding} variant="body1" color="secondary" sx={{padding: '0'}} style={{padding: '0'}} padding={0}>
                  <ul>
                    <li>1 Caractére Especial</li>
                    <li>1 Caractére Numérico</li>
                    <li>1 Caractére Maiúsculo</li>
                  </ul>
                </Typography>
              </div>
              <div style={{gap: '20px', display: 'flex', flexDirection: 'column', width: '80%'}}>
                <InputSignUpMemorized
                  onKeyUp={undefined}
                  icon={<GiPadlock {...iconsProps} />}
                  type="password"
                  name="password"
                  className="clientInput"
                  placeholder="Informe uma nova senha"
                  fullWidth
                  value={values.password}
                  onChange={handleChange}
                  style={undefined}
                />
                <InputSignUpMemorized
                  onKeyUp={undefined}
                  icon={<GiPadlock {...iconsProps} />}
                  className="clientInput"
                  type="password"
                  name="confirm_password"
                  placeholder="Repita a nova senha"
                  fullWidth
                  value={values.confirm_password}
                  onChange={handleChange}
                  style={undefined}
                />
              </div>
              <div style={{width: '80%'}}>
                <Button
                  className={`btn ${styles.buttonSend}`}
                  variant="contained"
                  size="large"
                  color="secondary"
                  type="submit"
                >
                  Enviar
                </Button>
              </div>
          </form>
      </Grid>
    </Core>
  )
}

