import Core from "../Core"
import { useState } from "react"
import { useFormik } from "formik"
import { toast } from 'react-toastify'
import { Link } from "react-router-dom"
import colors from "../../styles/colors"
//@ts-ignore
import styles from './styles.module.css'
import * as api from '../../services/api'
import { BsFillPersonFill } from "react-icons/bs"
import useWindowSize from "../../hooks/useWindowSize"
import { Button, Grid, Typography } from "@mui/material"
import InputSignUpMemorized from "../../components/InputSignUp"


export default function Forgot(): JSX.Element {
	const { width } = useWindowSize()
	const [disabledButton, setDisabledButton] = useState<boolean>(false)

	const { handleChange, handleSubmit, values } = useFormik({
		initialValues: {
			email: '', // Inicializando o campo email com uma string vazia
		},
		onSubmit: sendEmail
	})

	async function sendEmail() {
		setDisabledButton(true)
		toast.loading("Aguarde...", {
			position: toast.POSITION.TOP_RIGHT,
		})

		try {
			const response: any = await api.post(`user/password_reset/`, values)
			if (response.status === 200) {
				toast.dismiss()
				toast.success(`E-mail enviado para ${values.email}. Redirecionando, aguarde...`, {
					position: toast.POSITION.TOP_RIGHT
				})
				setTimeout(() => {
					window.location.pathname = '/reset'
				}, 5000)
			} else {
				setDisabledButton(false)
				toast.dismiss()
				toast.error("Não encontramos um usuário com este e-mail :(", {
					position: toast.POSITION.TOP_RIGHT
				})
			}
		} catch (error) {
			setDisabledButton(false)
			toast.dismiss()
			toast.error("Não encontramos um usuário com este e-mail :(", {
				position: toast.POSITION.TOP_RIGHT
			})
		}
	}

	const somosLogo = require("../../images/horizontalLogo.png")

	const iconsProps = {
        color: "white",
        size: width <= 768 ? 18 : 28
    }
	
	return (
		<Core>
			<Grid
				container
				height="100vh"
				flexDirection={width >= 900 ? "row" : "column"}
				textAlign="center"
				justifyContent="center"
				alignItems={width >= 900 ? "center" : 'center'}
				sx={{ backgroundColor: `${colors.DarkerGray}` }}
			>
				{width > 900 ? <> </> : <div className={styles.imageBack} style={{height: '100vh', width: '100%', position:'fixed', zIndex: '0'}} />}
				{
                    width > 900
                        ? <Grid
                            height={'100%'}
                            width={'50%'}
                            display={'flex'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            item
                        >
                            <div style={{height: '100%', width: '100%'}}>
                                <div className={styles.imageBack} style={{ width: '-webkit-fill-available', height: '100%', top: '0', zIndex: '0', position: 'fixed'}}/>
                            </div>
                            <img src={somosLogo} width={'100%'} height="auto" alt="Logo" style={{zIndex: '2', position: "fixed", width: '40%'}} />
                        </Grid>
                        : <Grid
                            container
                            height={'max-content'}
                            display={'flex'}
                            justifyContent={'center'}
                            width={width > 900 ? '50%' : '80%'}
                            flexDirection="row"
                            alignItems="center"
                        >
                            <img src={somosLogo} width={width <= 768 ? width === 768 ? '80%' : '100%' : '100%'} height="auto" alt="Logo" style={{zIndex: '2'}} />
                        </Grid>
                }

				<form onSubmit={handleSubmit} style={{width: width > 900 ? "50%" : '70%', display: 'flex', justifyContent: 'center'}}>
					<Grid
						container={width <= 900}
						item={width >= 900}
						flexDirection={width >= 900 ? "row" : "column"}
						gap={1}
					>
						<Typography position={'relative'} variant={width > 720 ? 'h4' : 'h6'} zIndex={2} color="primary" marginY={2}>
							<strong>RECUPERAR SENHA</strong>
						</Typography>
						<Grid
							container
							gap={2}
						>
							<InputSignUpMemorized
								onKeyUp={undefined}
								fullWidth
								type="email"
								name="email"
								value={values.email || ''} // Garantindo que value seja definido como uma string vazia se for undefined
								onChange={handleChange}
								className="clientInput"
								placeholder="Informe seu e-mail"
								icon={<BsFillPersonFill {...iconsProps} />}
								style={{borderRadius: '40rem'}}
							/>
						</Grid>
						<Typography zIndex={2} position={'relative'} color="secondary" variant="body1" marginY={4}>
							Retornar a tela de login? <Link to={`/`}>Clique aqui</Link>
						</Typography>
						<Button
							className={`btn-inverse ${styles.buttonSend}`}
							variant="contained"
							size="large"
							color="secondary"
							type="submit"
							disabled={disabledButton}
						>
							Enviar
						</Button>
					</Grid>
				</form>
			</Grid>
		</Core>
	)
} 