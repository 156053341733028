import App from './App'
import React from 'react'
import ReactDOM from 'react-dom/client'
// import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"
import { AuthProvider } from './contexts/authContext'
import "./index.css"

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)
root.render(
  <React.StrictMode>
    <AuthProvider>
      {/* <GoogleReCaptchaProvider reCaptchaKey="6LdOVSMiAAAAAPfG86w_c4gJ-vqwkwQYkKmcDyEr"> */}
        <App />
      {/* </GoogleReCaptchaProvider> */}
    </AuthProvider>
  </React.StrictMode>
)