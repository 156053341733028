import Core from "../Core"
import { useFormik } from 'formik'
import { toast } from "react-toastify"
import colors from "../../styles/colors"
import { useEffect, useState } from "react"
import { useAuth } from "../../hooks/useAuth"
import { Link, useNavigate } from "react-router-dom"
import useWindowSize from "../../hooks/useWindowSize"
//@ts-ignore
import styles from './styles.module.css'
import { GiPadlock } from 'react-icons/gi';
import { cookies } from "../../hooks/useCookies"
import { BsFillPersonFill } from "react-icons/bs"
import { Button, Grid, Typography } from "@mui/material"
import InputSignUpMemorized from "../../components/InputSignUp"

export default function Login(): JSX.Element {
	const { Login, toastLogin, setToastLogin } = useAuth()
	const { width } = useWindowSize()
	const navigate = useNavigate()
	const username = '';
	const password = '';

	const [disabledButton, setDisabledButton] = useState<boolean>(false)
	
	useEffect(() => {
		if(cookies.get('@Somos:toastSignUp') === 'true') {
			toast.success('Usuário cadastrado com sucesso, foi enviado uma senha temporária ao e-mail do cadastrado!', {position: toast.POSITION.TOP_RIGHT, autoClose: false})
			cookies.remove('@Somos:toastSignUp')
		}

		if(toastLogin) {
			toast.success('Nova senha cadastrada, faça login novamente!', {position: toast.POSITION.TOP_RIGHT, autoClose: false})
			setToastLogin(false)
		}
	}, [setToastLogin, toastLogin])

	const { handleChange, handleSubmit, values } = useFormik({
		initialValues: {
			username: '',
			password: ''
		},
		onSubmit: handleLogin
	})

	async function handleLogin() {
		setDisabledButton(true)
		await Login(values)
		setDisabledButton(false)
	}

	const somosLogo = require("../../images/horizontalLogo.png");

	const iconsProps = {
		color: "white",
		size: width <= 768 ? 18 : 28
	}

	return (
		<Core>
			<Grid
				container
				height="100vh"
				textAlign="center"
				justifyContent="center"
				alignItems={width > 900 ? "center" : 'center'}
				flexDirection={width > 1024 ? "row" : "column"}
				sx={{ backgroundColor: `${colors.DarkerGray}` }}
			>
				{width > 900 ? <> </> : <div className={styles.imageBack} style={{height: '100vh', width: '100%', position:'fixed', zIndex: '0'}} />}
				{width > 900 ? (
					<Grid
						height={'100%'}
						width={'50%'}
						display={'flex'}
						justifyContent={'center'}
						alignItems={'center'}
						item
					>
						<div style={{height: '100%', width: '100%'}}>
							<div className={styles.imageBack} style={{ width: '-webkit-fill-available', height: '100%', top: '0', zIndex: '0', position: 'fixed'}}/>
						</div>
						<img src={somosLogo} width={'100%'} height="auto" alt="Logo" style={{zIndex: '2', position: "fixed", width: '40%'}} />
					</Grid>
				) : (
					<Grid
						container
						height={'max-content'}
						display={'flex'}
						justifyContent={'center'}
						width={width > 900 ? '50%' : '80%'}
						flexDirection="row"
						alignItems="center"
					>
						<img src={somosLogo} width={'100%'} height="auto" alt="Logo" style={{zIndex: '2', marginTop: width <= 901 ? "50px" : "0"}} />
					</Grid>
				)}

				<form onSubmit={handleSubmit} style={{width: width > 900 ? "50%" : '100%', display: 'flex', justifyContent: 'center'}}>
					<Grid
						container={width <= 1024}
						item={width > 1024}
						flexDirection={width > 1024 ? "row" : "column"}
						width={'60%'}
						gap={1}
					>
						<Typography variant={width > 720 ? 'h4' : 'h6'} color="primary" marginY={2} zIndex={1} position={"relative"}>
							<strong>ÁREA DE ACESSO</strong>
						</Typography>
						<Grid
							container
							gap={3}
						>
							<InputSignUpMemorized
								type="email"
								onKeyUp={undefined}
								name="username"
								fullWidth={false}
								onChange={handleChange}
								style={{width: '100%'}}
								value={username}
								className="usernameLogin"
								placeholder="Informe seu e-mail" 
								icon={<BsFillPersonFill {...iconsProps} />}
							/>
							<InputSignUpMemorized
								type="password"
								name="password"
								onKeyUp={undefined}
								fullWidth={false}
								onChange={handleChange}
								style={{width: '100%'}}
								value={password}
								className="passwordLogin"
								placeholder="Informe sua senha"
								icon={<GiPadlock {...iconsProps} />}
							/>
						</Grid>
						<Typography zIndex={2} position={'relative'} color="secondary" variant="body1" marginY={4}>
							Esqueceu a senha? <Link to={`/forgot`}>Clique aqui</Link>
						</Typography>
						<div style={{gap: '20px', display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
							<Button
								size="large"
								type="submit"
								color="secondary"
								variant="contained"
								className="btn-inverse"
								disabled={disabledButton}
							>
								Acessar
							</Button>
							<Button
								size="large"
								color="secondary"
								variant="outlined"
								disabled={disabledButton}
								onClick={() => navigate('/signUp')}
								style={{borderRadius: '30px', width:'auto'}}
							>
								Cadastrar
							</Button>
						</div>
					</Grid>
				</form>
			</Grid>
		</Core>
	)
}