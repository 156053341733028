import { JsonTableStyles } from "./styles"

const ReactJsonTable = require('ts-react-json-table')

type JsonTableProps = {
  data: any
}

export default function JsonTable({ data }: JsonTableProps): JSX.Element {
  return (
    <JsonTableStyles>
      <ReactJsonTable rows={data} />
    </JsonTableStyles>
  )
}