import styled from "styled-components"
import colors from "../../styles/colors"

export const PlateChassiStyles = styled.div`
  display: flex;
  flex-direction: column;
  width: 700px;
  font-size: 10pt;
  color: ${colors.WaterBlue};
  text-align: justify;
  text-transform: uppercase;
  justify-content: center;

  i {
    font-size: 9pt;
    text-transform: none;
    margin-top: 10px;
  }

  table {
    background-color: ${colors.LowBlue};
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border-collapse: collapse;
    border-spacing: 0;
    margin-top: 15px;
    overflow: hidden;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    border-radius: 15px;
    width: ${window.innerWidth < 600 ? '95vw' : '50vw' };
  }

  table tr {
    border: none;
  }

  table tr.price {
    background-color: ${colors.WaterBlue};
    color: ${colors.IceWhite};
  }

  table tr.price td {
    border-left: ${colors.IceWhite} solid 1px;
  }
  
  table td {
    padding: 15px 30px;
    border-bottom: ${colors.IceWhite} solid 1px;
    border-left: ${colors.IceWhite} solid 1px;
  }

  table td.no-border {
    border-left: none;
  }

  .hash {
    font-size: 8pt;
    text-transform: none;
    text-align: center;
  }

  @media (max-width: 900px) {
    font-size: 7pt;
    margin: 0 20px;

    table {
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      border-radius: 10px;
    }

    table td {
      padding: 10px 15px;
    }

    i {
      font-size: 6pt;
    }

    .hash {
      font-size: 5pt;
    }
  }
`