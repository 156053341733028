import Core from "../../Core"
import { useFormik } from 'formik'
import { toast } from "react-toastify"
import * as api from "../../../services/api"
import { useParams } from "react-router-dom"
import { useCallback, useEffect } from "react"
import useWindowSize from "../../../hooks/useWindowSize"
import { DefaultMessage } from "../../../utils/defaultMessages"
import { Button, Grid, TextField, Typography } from "@mui/material"

export default function MyCompany(): JSX.Element {
  const { id } = useParams()
  const { width } = useWindowSize()

  const { handleChange, handleSubmit, values, setFieldValue } = useFormik({
    initialValues: {
      cnpj: '',
      email: '',
      number: '',
      fantasy_name: '',
      social_reason: '',
    },
    onSubmit: updateMyCompany
  })

  const getData = useCallback(async () => {
    await api.get(`company/read/${id}/`).then((response: any) => {
      setFieldValue('cnpj', response.data.content.cnpj)
      setFieldValue('email', response.data.content.email)
      setFieldValue('number', response.data.content.number)
      setFieldValue('api_user', response.data.content.api_user)
      setFieldValue('products', response.data.content.products)
      setFieldValue('fantasy_name', response.data.content.fantasy_name)
      setFieldValue('social_reason', response.data.content.social_reason)
      setFieldValue('prepaid_plan', response.data.content.prepaid_plan)
      setFieldValue('contract_end', response.data.content.contract_end)
      setFieldValue('contract_start', response.data.content.contract_start)
    }).catch((error) => {
      toast.error(DefaultMessage.ERROR, {
        position: toast.POSITION.TOP_RIGHT
      })
    })
  }, [id, setFieldValue])

  async function updateMyCompany() {
    toast.loading("Aguarde...", {
      position: toast.POSITION.TOP_RIGHT,
    })

    const updateUrl = `company/update/${id}/`

    await api.put(updateUrl, {'email': values.email, 'number': values.number}).then((response: any) => {
      toast.dismiss()
      if (response.status === 200) {
        toast.success("Dados atualizado com sucesso!", {
          position: toast.POSITION.TOP_RIGHT
        })
      } else {
        toast.error(DefaultMessage.ERROR, {
          position: toast.POSITION.TOP_RIGHT
        })
      }
    }).catch((error) => {
      toast.dismiss()
      toast.error(DefaultMessage.ERROR, {
        position: toast.POSITION.TOP_RIGHT
      })
    })
  }

  useEffect(() => {
    if (id) getData()
  }, [getData, id])

  return (
    <Core 
      withBlueBar 
      withNavbar 
    >
      <Grid
        container
        direction="column"
        alignContent="center"
        alignItems="center"
        textAlign="center"
        paddingBottom={10}
      >
        <form onSubmit={handleSubmit}>
          <Grid
            container
            spacing={2}
            textAlign="center"
            justifyContent="space-around"
            paddingX={width > 600 ? 20 : 2}
          >
            <Grid
              item
              md={4} sm={12}
            >
              <Typography variant="body1">
                Razão social
              </Typography>
              <TextField
                fullWidth
                type="text"
                id="social_reason"
                name="social_reason"
                placeholder="Razão social"
                value={values.social_reason}
                onChange={handleChange}
                disabled
              />
            </Grid>
            <Grid
              item
              md={4} sm={12}
            >
              <Typography variant="body1">
                CNPJ
              </Typography>
              <TextField
              fullWidth
                type="text"
                id="cnpj"
                name="cnpj"
                placeholder="CNPJ"
                value={values.cnpj}
                onChange={handleChange}
                disabled
              />
            </Grid>
            <Grid
              item
              md={4} sm={12}
            >
              <Typography variant="body1">
                Nome fantasia
              </Typography>
              <TextField
                fullWidth
                type="text"
                id="fantasy_name"
                name="fantasy_name"
                placeholder="Nome fantasia"
                value={values.fantasy_name}
                onChange={handleChange}
                disabled
              />
            </Grid>
            <Grid
              item
              md={6} sm={12}
            >
              <Typography variant="body1">
                E-mail
              </Typography>
              <TextField
              fullWidth
                type="text"
                id="email"
                name="email"
                placeholder="E-mail"
                value={values.email}
                onChange={handleChange}
              />
            </Grid>
            <Grid
              item
              md={6} sm={12}
            >
              <Typography variant="body1">
                Telefone / Celular
              </Typography>
              <TextField
                fullWidth
                type="text"
                id="number"
                name="number"
                placeholder="Telefone / Celular"
                value={values.number}
                onChange={handleChange}
              />
            </Grid>
            <Grid
              item
            >
              <Button
                className="btn-inverse"
                variant="contained"
                size="large"
                color="secondary"
                type="submit"
              >
                {
                  id ? "Atualizar" : "Cadastrar"
                }
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Core>
  )
}