import Core from "../../Core"
import { useFormik } from "formik"
import { toast } from "react-toastify"
import React, { useState } from "react"
import { Link } from "react-router-dom"
//@ts-ignore
import styles from './styles.module.css'
import { GiPadlock } from "react-icons/gi"
import colors from "../../../styles/colors"
import * as api from '../../../services/api'
import { cookies } from '../../../hooks/useCookies'
import useWindowSize from "../../../hooks/useWindowSize"
import { Button, Grid, Typography } from "@mui/material"
import InputSignUpMemorized from "../../../components/InputSignUp"


export default function Reset(): JSX.Element {
	const { width } = useWindowSize()
	const [disabledButton, setDisabledButton] = useState<boolean>(false)

	const somosLogo = require("../../../images/horizontalLogo.png")


	const { handleChange, handleSubmit, values } = useFormik({
		initialValues: {
			token: '',
		},
		onSubmit: validateToken
	})

	async function validateToken() {
		setDisabledButton(true)
		toast.loading("Aguarde...", {
			position: toast.POSITION.TOP_RIGHT,
		})

		await api.post(
			`user/password_reset/confirm/validate_token/`, values
		).then((response: any) => {
			if (response.status === 200) {
				cookies.set("@Somos:PasswordToken", values.token, { path: '/', sameSite: 'lax' })
				setTimeout(() => {
					toast.dismiss()
					window.location.pathname = '/new-password'
				}, 5000)
			} else {
				setDisabledButton(false)
				toast.dismiss()
				toast.error("Token Inválido. Verifique se o Token informado está igual ao enviado no seu email ou solicite um novo!", {
					position: toast.POSITION.TOP_RIGHT
				})
			}
		}).catch((error) => {
			setDisabledButton(false)
			toast.dismiss()
			toast.error("Token Inválido. Verifique se o Token informado está igual ao enviado no seu email ou solicite um novo!", {
				position: toast.POSITION.TOP_RIGHT
			})
		})
	}

	const iconsProps = {
		color: "white",
		size: width <= 768 ? 18 : 28
	}

	return (
		<Core>
			<Grid
				container
				height="100vh"
				flexDirection={width > 1024 ? "row" : "column"}
				textAlign="center"
				justifyContent="center"
				alignItems={width > 1024 ? "center" : 'center'}
				sx={{ backgroundColor: `${colors.DarkerGray}` }}
			>
				{width > 900 ? <> </> : <div className={styles.imageBackMobile} style={{ height: '100vh', width: '100%', position: 'fixed', zIndex: '0' }} />}
				{
					width > 900
						? <Grid
							height={'100%'}
							width={'50%'}
							display={'flex'}
							justifyContent={'center'}
							alignItems={'center'}
							item
						>
							<div style={{ height: '100%', width: '100%' }}>
								<div className={styles.imageBack} style={{ width: '-webkit-fill-available', height: '100%', top: '0', zIndex: '0', position: 'fixed' }} />
								{/* <div style={{position: 'absolute', height: '100%', width: '-webkit-fill-available', top: '0', zIndex: '1', backgroundColor: '#000000d4' }}/> */}
							</div>
							<img src={somosLogo} width={'100%'} height="auto" alt="Logo" style={{ zIndex: '2', position: "fixed", width: '40%' }} />
						</Grid>
						: <Grid
							container
							height={'max-content'}
							display={'flex'}
							justifyContent={'center'}
							width={width > 900 ? '50%' : '80%'}
							flexDirection="row"
							alignItems="center"
							marginBottom={2}
						>

							<img src={somosLogo} width={'100%'} height="auto" alt="Logo" style={{ zIndex: '2', marginTop: width <= 901 ? "50px" : "0" }} />
						</Grid>
				}

				<form onSubmit={handleSubmit} style={{width: width >= 900 ? '50%' : '100%', zIndex: 3, alignItems: 'center', display: 'flex', justifyContent: 'center', gap: '30px', flexDirection: 'column'}}>
					<Grid
						gap={1}
						width={'70%'}
						item={width > 1024}
						alignItems={'center'}
						container={width <= 1024}
						flexDirection={width > 1024 ? "row" : "column"}
					>
						<Typography variant={width > 720 ? 'h4' : 'h6'} color="primary" marginY={4}>
							<strong>RECUPERAÇÃO DE SENHA</strong>
						</Typography>
						<Grid
							container
							gap={2}
						>
							<InputSignUpMemorized
								fullWidth
								name="token"
								type={"text"} 
								style={undefined}
								onKeyUp={undefined}
								value={values.token}
								onChange={handleChange}
								className="clientInput"
								icon={<GiPadlock {...iconsProps} />}
								placeholder="Informe o token que chegou no seu e-mail" 
							/>
						</Grid>
						<Typography color="secondary" variant="body1" marginY={4}>
							Fazer login? <Link to={`/`}>Clique aqui</Link>
						</Typography>
						<Button
							className={`btn ${styles.buttonSend}`}
							variant="contained"
							size="large"
							color="secondary"
							type="submit"
							disabled={disabledButton}
						>
							Enviar
						</Button>
					</Grid>
				</form>
			</Grid>
		</Core>
	)
}
