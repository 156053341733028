import React, { useEffect, useState } from 'react';
import { ApexOptions } from 'apexcharts';
import ReactApexChart from 'react-apexcharts';

interface MyComponentProps {
  title: string;
  categories: string[];
  consults: number[];
  colorProp: string;
}

const ColumnChart: React.FC<MyComponentProps> = ({ title, categories, consults, colorProp }) => {

  const [chartState, setChartState] = useState<{
    series: { name: string, data: number[] }[],
    options: ApexOptions
  }>({
    series: [{
      name: 'Consultas',
      data: consults
    }],
    options: {
      annotations: {
        points: [{
          x: 'Produtos',
          seriesIndex: 0,
          label: {
            borderColor: '#775DD0',
            offsetY: 0,
            style: {
              color: '#fff',
              background: '#775DD0',
            },
            text: '',
          }
        }]
      },
      chart: {
        height: 400,
        width: 800,
        type: 'bar',
        zoom: {
          enabled: false
        },
      },
      colors: [colorProp],
      plotOptions: {
        bar: {
          borderRadius: 10,
          columnWidth: '25%',
        }
      },
      dataLabels: {
        enabled: true
      },
      stroke: {
        width: 0
      },
      grid: {
        row: {
          colors: ['#f2f2f2']
        }
      },
      xaxis: {
        labels: {
          rotate: -45,
          style: {
            fontSize: window.innerWidth < 600 ? '8px' : '.8rem'
          },
          formatter: function (value: string) {
            if (typeof value === 'string') {
              return value.length > 15 ? value.substring(0, 15) + '...' : value; // Exibe apenas os primeiros 15 caracteres
            }
            return ''; // Retorna uma string vazia se o valor não for uma string
          }
        },
        categories,
        tickPlacement: 'on'
      },
      tooltip: {
        y: {
          formatter: function (value: number, { seriesIndex, dataPointIndex }) {
            const category = categories[dataPointIndex];
            return `${category}: ${value}`; // Exibe a string completa no tooltip
          }
        }
      },
      yaxis: {
        title: {
          text: 'Consultas',
        },
        labels: {
          formatter: function (value: number) {
            return Math.floor(value).toString(); // Converte o número para string
          }
        },
        min: 0, // Define o valor mínimo do eixo Y
        max: Math.max(...consults) + 10, // Define o valor máximo do eixo Y, com um pequeno buffer
        tickAmount: 10, // Define o número de ticks no eixo Y
        forceNiceScale: true, // Ajusta a escala de maneira mais adequada
      },
    },
  });

  useEffect(() => {
    setChartState((prevChartState) => ({
      ...prevChartState,
      series: [{
        name: 'Consultas',
        data: consults
      }],
      options: {
        ...prevChartState.options,
        xaxis: {
          ...prevChartState.options.xaxis,
          categories,
        },
      },
    }));
  }, [categories, consults]);  

  return (
    <div>
      <div id="chart">
        <ReactApexChart className='w-max-[200vw]' options={chartState.options} series={chartState.series} type="bar" height={400} />
      </div>
      <div id="html-dist"></div>
    </div>
  );
}

export default ColumnChart;