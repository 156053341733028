import "./styles.css";
import React from 'react';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { Button } from "@mui/material";
import { FaDownload } from "react-icons/fa";

export const DoubleChassiModal = (props: any) => {

    document?.getElementById('divModal')?.addEventListener('click', function (e) {
        if (e.target === document.getElementById('divModal')) {
            props.setModal()
        }
    });

    function jsonToXLSXSingleColumn(jsonData: any[], columnName: string, fileName: string) {
        const data = jsonData.map(item => [item]);

        data.unshift([columnName]);
      
        const worksheet = XLSX.utils.aoa_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
        saveAs(blob, fileName + '.xlsx');
    }

    return (
        props.state ?
            <div id="divModal" style={{ display: 'flex', position: 'fixed', top: '0%', height: '100vh', width: '100%', background: 'rgba(1, 1, 1, 0.5)', backdropFilter: 'blur(2px)', zIndex: '1500', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                <div className="cardModal">
                    <div className='titleEdit'><h3 style={{ alignSelf: "end" }}>CHASSIS DUPLICADOS</h3><Button onClick={() => jsonToXLSXSingleColumn([...props.chassis], 'Chassis Duplicados', `chassis-duplicados-${Date.now()}`)} sx={{ backgroundColor: '#00AEEF', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '.5rem', '&:hover': { backgroundColor: '#0086B3' } }}>Extrair <FaDownload size='15' style={{ color: 'white' }} /></Button></div>
                    <div className='titleEdit'><h6 style={{ alignSelf: "end" }}>{props.message}</h6></div>
                    <div className='inputsContainer'>
                        {props.chassis.map((item: any, index: number) =>
                            <div key={index} className="inputDiv">
                                {item}
                            </div>
                        )}
                        {
                            window.innerWidth < 990 ?
                                <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', border: '1px solid white' }}><button onClick={() => { props.setModal() }} className='buttonOkDoubleChassi'>OK</button></div>
                                : <></>
                        }
                    </div>
                </div>
            </div>
            : <></>
    );
};
