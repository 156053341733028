import { memo, useState } from "react"
//@ts-ignore
import styles from "../../pages/Login/SignUp/switchButton.module.css"

type propsT = {
    firstLabel: string
    secondLabel: string
    withoutMarginTop?: boolean
}

const SwitchButton = ({ firstLabel, secondLabel, withoutMarginTop }: propsT) => {

    const [option, setOption] = useState(1)

    return (
        secondLabel === 'Administradores' ?

            <div className={styles['switches-container']} style={{ marginTop: withoutMarginTop ? '0px' : '20px' }}>
                <input
                    type="radio"
                    id="switchMonthly"
                    name="switchPlan"
                    value="Monthly"
                    defaultChecked
                />
                <input
                    type="radio"
                    id="switchYearly"
                    name="switchPlan"
                    value="Yearly"
                />
                <div
                    onClick={() => {
                        if (option === 1) {
                            document.getElementById('labelToDiv1')?.click()
                            return
                        } else {
                            setOption(1)
                            document.getElementById('switch')?.click()
                            document.getElementById('labelToDiv1')?.click()
                            return
                        }
                    }
                    }
                    id="divForLabel1"
                    style={{ width: "50%", height: '100%', position: 'absolute', zIndex: '3', borderRadius: '3rem 0px 0px 3rem', cursor: option === 1 ? "auto" : "pointer" }}
                />
                <label
                    style={{ zIndex: '0' }}
                    id="labelToDiv1"
                    htmlFor="switchMonthly"
                >
                    {firstLabel}
                </label>
                <div
                    onClick={() => {
                        if (option === 2) {
                            document.getElementById('labelToDiv2')?.click()
                            return
                        } else {
                            setOption(2)
                            document.getElementById('switch')?.click()
                            document.getElementById('labelToDiv2')?.click()
                            return
                        }
                    }
                    }
                    id="divForLabel2"
                    style={{ width: "50%", height: '100%', position: 'absolute', zIndex: '3', right: '0', borderRadius: '0 3rem 3rem 0', cursor: option === 2 ? "auto" : "pointer" }}
                />
                <label
                    id="labelToDiv2"
                    style={{ zIndex: '0' }}
                    htmlFor="switchYearly"
                >
                    {secondLabel}
                </label>
                <div className={styles['switch-wrapper']}>
                    <div className={styles.switch}>
                        <div>{firstLabel}</div>
                        <div>{secondLabel}</div>
                    </div>
                </div>
            </div>
            : <div className={styles['switches-container']} style={{ marginTop: withoutMarginTop ? '0px' : '20px', width: 'auto', padding: '0 20px' }}>
                <label
                    style={{ zIndex: '0' }}
                    id="labelToDiv1"
                    htmlFor="switchMonthly"
                >
                    {firstLabel}
                </label>
            </div>
    )

}

export default memo(SwitchButton)