import styled from "styled-components"
import colors from "../../styles/colors"

export const CardSomosStyle = styled.div`
  background-color: ${colors.DarkerGray};
  color: ${colors.IceWhite};
  display: flex;
  margin: 50px 20px;
  padding: 50px 80px;
  gap: 20px;
  width: auto;
  border-radius: 15px;
`

export const CardSomosMobileStyle = styled.div`
  background-color: ${colors.DarkerGray};
  color: ${colors.IceWhite};
  display: flex;
  margin: 50px 20px;
  padding: 50px 20px;
  gap: 20px;
  width: auto;
  border-radius: 15px;
`