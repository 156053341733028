import styled from "styled-components"
import colors from "../../styles/colors"
import { Typography } from "@mui/material";

export const BlueBarStyles = styled.div`
	display: flex;
  top: 78px;
	position: fixed;
	left: 0px;
	background-color: ${colors.IceBlue};
	width: 100%;
	height: 60px;
	justify-content: center;
	align-items: center;
	z-index: 1;
`

export const ResponsiveTypography = styled(Typography)`
  && {
    @media (max-width: 600px) {
      font-size: 1.1rem;
    }
  }
`;