import { Card } from "./styles"
import { Test } from "./styles"
import useWindowSize from "../../hooks/useWindowSize"

interface CardButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  color: string
  name?: string
  small?: boolean
  icon: React.ReactNode
  backgroundColor: string
}

export default function CardButton(
  {
    icon,
    name,
    color,
    backgroundColor,
    small = false,
    ...props
  }: CardButtonProps
): JSX.Element {

  const defaultSize = 200
  const { width } = useWindowSize()
  const smallSize = width > 400 ? 70 : 55
  let size = small ? smallSize : defaultSize

  const propsCard = {color, backgroundColor, size, small, ...props}

  return (
    <Card {...propsCard}>
      <span>
        {icon}
      </span>
      {
        name && !small
          ? <div
            className="fdiv"
          >
            <Test>
              <div 
                className="ftdiv"
              >
                {
                  name
                }
              </div>
            </Test>
          </div>
          : <></>
      }
    </Card>
  )
}