import Login from '../pages/Login'
import Forgot from '../pages/Forgot'
import Reset from '../pages/Login/Reset'
import NewPassword from '../pages/Login/NewPassword'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import SignUp from '../pages/Login/SignUp'

export default function SignRoutes(): JSX.Element {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='*' element={<Login />} />
        <Route path='/' element={<Login />} />
        <Route path='/reset' element={<Reset />} />
        <Route path='/signUp' element={<SignUp/>} />
        <Route path='/forgot' element={<Forgot />} />
        <Route path='/new-password' element={<NewPassword />} />
      </Routes>
    </BrowserRouter>
  )
}