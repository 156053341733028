import AppRoutes from "./routes"
import colors from "./styles/colors"
import { useAuth } from "./hooks/useAuth"
import GlobalStyles from "./styles/global"
import '@coreui/coreui/dist/css/coreui.min.css'
import 'react-toastify/dist/ReactToastify.min.css'

function App() {

  const { signed } = useAuth()

  return (
      <>
        <AppRoutes />
        <GlobalStyles backcolor={signed ? colors.IceWhite : colors.DarkerGray} overflowsigned={signed} />
      </>
  );
}

export default App