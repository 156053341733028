import Core from "../../Core"
import { toast } from "react-toastify"
import { CompanyProps } from "../types"
import * as api from "../../../services/api"
import { useNavigate } from "react-router-dom"
import { Fragment, useEffect, useState } from "react"
import useWindowSize from "../../../hooks/useWindowSize"
import GroupRoundedIcon from '@mui/icons-material/GroupRounded'
import { DefaultMessage } from "../../../utils/defaultMessages"
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded'
import BusinessRoundedIcon from '@mui/icons-material/BusinessRounded'
import { Avatar, Button, Chip, Dialog, DialogActions, DialogContent, Divider, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, TextField, Typography } from "@mui/material"

export default function Companies(): JSX.Element {
  const navigate = useNavigate()
  const { width } = useWindowSize()
  const [data, setData] = useState<CompanyProps[]>()
  const [dataSearch, setDataSearch] = useState<CompanyProps[]>()
  const [idCompanyToDelete, setIdCompanyToDelete] = useState<string>()
  const [nameCompanyToDelete, setNameCompanyToDelete] = useState<string>()

  const getData = async () => {
		await api.get('company/list/').then((response: any) => {
      if (response.status === 200) {
        setData(response.data.content)
        setDataSearch(response.data.content)
      } else {
        toast.error(DefaultMessage.ERROR, {
          position: toast.POSITION.TOP_RIGHT
        })
      }
    }).catch((error) => {
      toast.error(DefaultMessage.ERROR, {
				position: toast.POSITION.TOP_RIGHT
			})
    })
	}

  function handleOpenDialog(id: string, name: string) {
    setIdCompanyToDelete(id)
    setNameCompanyToDelete(name)
	}

  const deleteCompany = async () => {
    toast.dismiss()
    toast.loading(
      "Aguarde...", {
			  position: toast.POSITION.TOP_RIGHT,
		  }
    )
		
    await api.destroy(`company/delete/${idCompanyToDelete}/`).then(async (response: any) => {
      toast.dismiss()
      if (response.status === 204) {
        await getData()
        toast.success("Empresa excluída com sucesso!", {
          position: toast.POSITION.TOP_RIGHT
        })
      } else {
        toast.error(DefaultMessage.ERROR, {
          position: toast.POSITION.TOP_RIGHT
        })
      }
    }).catch((error) => {
      toast.dismiss()
      toast.error(DefaultMessage.ERROR, {
			  position: toast.POSITION.TOP_RIGHT
		  })
    })
	}

  const DialogComponent = (
		<Dialog
			open={idCompanyToDelete !== undefined}
			onClose={() => {
				setIdCompanyToDelete(undefined)
				setNameCompanyToDelete(undefined)
			}}
			aria-describedby="alert-dialog-description"
		>
			<DialogContent>
        <Typography color="black">
          Tem certeza que deseja excluir a empresa {nameCompanyToDelete}?
        </Typography>
        <Typography color="red">
          Essa operação não poderá ser desfeita
        </Typography>
			</DialogContent>
			<DialogActions>
				<Button 
          onClick={
            () => {
					    setIdCompanyToDelete(undefined)
					    setNameCompanyToDelete(undefined)
				    }
          } 
          color="primary"
        >
					NÃO
				</Button>
        <Button 
          onClick={
            () => {
					    deleteCompany()
					    setIdCompanyToDelete(undefined)
					    toast.success("Empresa excluída com sucesso!", {
						    position: toast.POSITION.TOP_RIGHT
					    })
				    }
          }
        >
          SIM
        </Button>
			</DialogActions>
		</Dialog>
	)

  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value
    search(value)
  }

  function search(value: string) {
    setDataSearch(
      data?.filter(
        (item) => item.social_reason.toLowerCase().indexOf(value.toLowerCase()) > -1 ||
          item.fantasy_name.toLowerCase().indexOf(value.toLowerCase()) > -1 ||
          item.email.toLowerCase().indexOf(value.toLowerCase()) > -1 ||
          item.cnpj.toLowerCase().indexOf(value.toLowerCase()) > -1
      )
    )
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <Core withNavbar withBlueBar path="/new-company" label="Clique aqui para cadastrar uma nova empresa">
      {DialogComponent}
      <Grid
        container
        direction="column"
        alignContent="center"
        alignItems="center"
        textAlign="center"
        paddingBottom={10}
        paddingX={width > 600 ? 10 : 2}
        gap={2}
      >
          <Chip label="Empresas cadastradas" />
          <TextField 
            type="text" 
            placeholder="Pesquisar" 
            fullWidth 
            onChange={handleChangeSearch} 
          />
          {
            <List sx={{ width: '90%', bgcolor: 'background.paper' }}>
              {
                dataSearch?.map(
                  (item, index) => 
                    <div key={`company-item-${index}`}>
                      <ListItem
                        alignItems="flex-start"
                        secondaryAction={
                          <Fragment>
                            <IconButton 
                              sx={{ mr: 2 }} 
                              edge="end" 
                              aria-label="delete"
                              onClick={
                                () => {
                                  navigate(
                                    `/users/${item.id}`,
                                  )
                                }
                              }
                            >
                              <GroupRoundedIcon />
                            </IconButton>
                            <IconButton 
                              edge="end" 
                              aria-label="delete"
                              onClick={() => {handleOpenDialog(item.id, item.social_reason)}}
                            >
                              <DeleteRoundedIcon />
                            </IconButton>                        
                          </Fragment>
                        }
                      >
                        <ListItemAvatar>
                          <Avatar>
                            <BusinessRoundedIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemButton 
                          onClick={
                            () => {
                              navigate(
                                `/company/${item.id}`,
                              )
                            }
                          }
                        >
                          <ListItemText 
                            primary={item.social_reason} 
                            secondary={
                              <Fragment>
                                <i style={{ color: "black" }}>{item.cnpj}</i>
                                <br />
                                <i style={{ color: "black" }}>{item.email}</i>
                              </Fragment>
                            }
                          />
                        </ListItemButton>
                      </ListItem>
                      <Divider variant="inset" component="li" />
                    </div>
                )
              }
            </List>
          }
      </Grid>
    </Core>
  )

}