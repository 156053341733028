import styled from "styled-components"
import colors from "../../styles/colors"

const defaultSize = 200;

interface CardPropsType {
  size: number;
  color: string;
  backgroundColor: string;
  small: boolean
}

export const CardButtonStyle = styled.button`
  width: ${defaultSize}px;
  height: ${defaultSize}px;
  display: flex;
  color: ${colors.WaterBlue};
  background-color: ${colors.LoginBlue};
  border-radius: ${defaultSize/5}px;
  align-items: center;
  justify-content: space-around;
  border: none;
  border-bottom: ${colors.WaterBlue} solid .4em;
  border-right: ${colors.WaterBlue} solid .4em;
  cursor: pointer;

  span {
    font-size: ${defaultSize/34}rem;
  }
`;

export const Card = styled(CardButtonStyle)<CardPropsType>`
    color: ${(props) => props.color};
    width: ${(props) => props.size}px;
    height: ${(props) => props.size}px;
    position: relative;
    box-sizing: border-box;
    border-radius: ${(props) => props.size / 5}px;
    border-right: ${(props) => props.color} solid .4em;
    border-bottom: ${(props) => props.color} solid .4em;
    background-color: ${(props) => props.backgroundColor};
    ${(props) => props.small ? `border: ${props.color} solid .2em;` : ``}

    span {
      font-size: ${(props) => props.size / 34}rem;
    }
    
    transition: all 0s;

    .fdiv {
      position: absolute;
      width: 100%;
      height: 100%;
    }

  `

  export const Test = styled.div`
    opacity: 0;
    height: 100%;
    display: flex;
    padding-top: 20px;
    align-items: flex-start;
    justify-content: center;

    &:hover {
      opacity: 1
    }

    .ftdiv {
  
      width: 80%;
      color: white;
      text-lign: center;
      border-radius: 1rem;
      height: max-content;
      background-color: ${colors.WaterBlue};
        
    }
  `