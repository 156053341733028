const colors = {
    "OceanDarkBlue": "#114084",
    "OceanLightBlue": "#3466AA",
    "IceBlue": "#00AEEF",
    "WaterBlue": "#006991",
    "IceWhite": "#F1F1F1",
    "EskimoGray": "#808080",
    "PenguinBlack": "#1C1C1C",
    "ChillyWillyRed": "#FF7276",
    "MoonYellow": "#F4E06D",
    "FogGray": "#BDBDBD",
    "DarkerGray": "#252028",
    "LoginBlue": "#00B9F2",
    "LowBlue": "#D8E7EE",
}

export default colors   