import { z } from "zod";
import Core from "../Core";
import ApexChartsModule from './pie';
import MultiSelect from "./multiselect";
import * as api from "../../services/api";
import { CompanyProps } from "../Company/types";
import React, { useCallback, useEffect, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import ColumnChart from "./column";
import { useAuth } from "../../hooks/useAuth";
import { TbFileSad } from "react-icons/tb";
import { toast } from "react-toastify";

type ProductRanking = {
    product: string;
    total_count: number;
};

type CompanyIdName = {
    [key: string]: string;
}

const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
};

const filterSchema = z.object({
    end_date: z
        .string()
        .min(1, 'Data inválida')
        .refine((date) => {
            const [year] = date.split('-');
            return year.length <= 4;
        }, {
            message: 'O ano deve ter no máximo 4 dígitos',
        }),
    start_date: z
        .string()
        .min(1, 'Data inválida')
        .refine((date) => {
            const [year] = date.split('-');
            return year.length <= 4;
        }, {
            message: 'O ano deve ter no máximo 4 dígitos',
        }),
    company: z.string().array().min(1, 'Deve haver ao menos uma empresa'),
    product: z.string().array().min(1, "Deve haver ao menos um produto"),
})

const StatisticsScreen = () => {

    const [clients, setClients] = useState([]);
    const [page, setPage] = useState<number>(1);
    const [dataSaved, setDataSaved] = useState<object>({});
    const [totalPages, setTotalPages] = useState<number>(1);
    const [filtered, setFiltered] = useState<boolean>(false);
    const [prodsArray, setProdsArray] = useState<ProductRanking[]>([]);
    const [biggestCount, setBiggestCount] = useState<number>(1);
    const [companies, setCompanies] = useState<CompanyProps[]>();
    const [companiesOptions, setCompaniesOptions] = useState<CompanyIdName>({ "": "" });
    const { user } = useAuth();

    type FormInputTypes = z.infer<typeof filterSchema>;

    const todayDate = getTodayDate();

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm<FormInputTypes>({
        mode: "all",
        resolver: zodResolver(filterSchema),
        defaultValues: {
            start_date: undefined,
            end_date: undefined,
            company: undefined,
            product: []
        }
    })

    useEffect(() => {

        if (companies !== null || companies !== undefined) {
            type MapeamentoEntidades = Record<string, string>;
            let objetoTransformado: MapeamentoEntidades = companies?.reduce<MapeamentoEntidades>((acc, item) => {
                acc[item.fantasy_name] = item.id;
                return acc;
            }, {}) || {};
            setCompaniesOptions(objetoTransformado);
        }

    }, [companies])

    const submeter = async (data: FormInputTypes) => {

        setDataSaved(data);

        let iDateStr = new Date(data.start_date);
        let fDateStr = new Date(data.end_date);
        const offsetInit = iDateStr.getTimezoneOffset() * 60000;
        const offsetFinal = fDateStr.getTimezoneOffset() * 60000;
        data.start_date = new Date(iDateStr.getTime() + offsetInit).toISOString().split('T')[0];
        data.end_date = new Date(fDateStr.getTime() + offsetFinal).toISOString().split('T')[0];
        data.product = [String(data.product).replaceAll(",", "&product=")];
        data.company = [String(data.company).replaceAll(",", "&company=")];

        let urlParameters = Object.entries(data).map(e => e.join("=")).join("&");
        urlParameters = urlParameters.replaceAll("&company=&", "&")
        const url = `/table/api/stats/?${urlParameters}`;
        const resp: any = await api.get(url);

        if (resp.status === 200 || resp.status === 202 || resp.status === 201) {
            setProdsArray(resp.data.content.ranking);
            if ((resp.data.content.ranking.length === 1 && resp.data.content.ranking[0].total_count === 0)) {
                setFiltered(true)
            } else {
                setFiltered(false)
            }
        }
        if (user?.is_admin) {
            getClients(data, 1);
        }

    }

    const getClients = (data: any, page: number) => {

        const urlClients = `/table/api/stats/client/?start_date=${data.start_date}&end_date=${data.end_date}&page=${page}`;

        api.get(urlClients).then((response: any) => {
            setClients(response.data.content.ranking.sort((a: any, b: any) => b.total_count - a.total_count).map(({ company_name, total_count }: any) => ({ company_name, total_count })));
            setBiggestCount(response.data.content.ranking[0].total_count);
            setTotalPages(response.data.content.pages);
        }).catch((error) => {
            setFiltered(true)
            toast.error('Ocorreu um erro. Favor, tente novamente.', {
                position: toast.POSITION.TOP_RIGHT
            })
        })

    }

    const getCompany = async (id: string) => {
        await api.get(`/user/list/company/?company=${id}`).then((response: any) => {
            if (response.status === 403) {
                const newCompany: CompanyIdName = {
                    "Minha Empresa": user!.company_id
                }
                setCompaniesOptions(newCompany);
                setValue('company', [user!.company_id]);
            }
            const myCompanyOnly: CompanyProps = {
                id: response.data.content[0].company_id,
                email: response.data.content[0].email,
                api_user: false,
                products: [''],
                fantasy_name: response.data.content[0].company_name,
                social_reason: response.data.content[0].company_name,
                cnpj: '',
                number: '',
                is_active: true,
                prepaid_plan: true,
                contract_end: '',
                contract_start: '',
            }
            setCompanies([myCompanyOnly]);
        });
    }

    const getCompanies = useCallback(async () => {
        await api.get('company/list/').then((response: any) => {

            if (response.status === 403 && user?.company_id) {
                getCompany(user?.company_id)
            }

            setCompanies(response.data.content);
            setFiltered(false);

        }).catch((error) => {
            setFiltered(true)
            toast.error('Ocorreu um erro. Favor, tente novamente.', {
                position: toast.POSITION.TOP_RIGHT
            })
        })
    }, []);

    useEffect(() => {
        getCompanies();
    }, [getCompanies])

    const handlePage = (page: number, forward: boolean) => {
        if (page < totalPages && forward) {
            setPage(page + 1);
            getClients(dataSaved, page);
        } else if (page > 1 && !forward) {
            setPage(page - 1);
            getClients(dataSaved, page);
        }
        else {
            return
        }
    }

    return (
        <Core withNavbar withBlueBar label="Tela de Estatística" >
            <main className="w-full h-auto px-4 gap-4 flex flex-wrap -py-5">
                <form className="flex flex-col py-4 sm:h-[16vh] justify-between gap-3 sm:gap-5 w-full px-5 mb-1 bg-white sm:py-3 rounded-2 items-center sm:flex-row" onSubmit={handleSubmit(submeter)}>
                    <label className="w-full sm:w-[25%] flex flex-col relative min-h-[70px] sm:min-h-[90px]">
                        <span className="font-semibold">Data Inicial</span>

                        <input type="date" className="border border-gray-400 h-11 rounded-full" {...register("start_date")} max={todayDate}></input>
                        {errors.start_date && <p className="text-red-400 absolute top-3/4">{errors.start_date.message}</p>}
                    </label>
                    <label className="w-full sm:w-[25%] flex flex-col relative min-h-[70px] sm:min-h-[90px]">
                        <span className="font-semibold">Data Final</span>
                        <input type="date" {...register("end_date")} className="border border-gray-400 w-full h-11 rounded-full" max={todayDate}></input>
                        {errors.end_date && <p className="text-red-400 absolute top-3/4">{errors.end_date.message}</p>}
                    </label>
                    <label className={`w-full sm:w-[25%] flex-col relative min-h-[70px] sm:min-h-[90px] ${user?.is_admin || user?.is_representative ? 'flex' : 'hidden'}`}>
                        <span className="font-semibold">Empresa</span>
                        {companiesOptions && (
                            <MultiSelect
                                fieldNameRegister="company"
                                register={register}
                                error={errors.company?.message}
                                setValue={setValue}
                                optionsProps={user?.is_admin || user?.is_representative ? { ...companiesOptions } : { 'Minha Empresa': user!.company_id }}
                            />
                        )}
                    </label>
                    <label className="w-full sm:w-[25%] flex flex-col relative min-h-[70px] sm:min-h-[90px]">
                        <span className="font-semibold">Produto</span>
                        <MultiSelect fieldNameRegister="product" setValue={setValue} register={register} error={errors.product?.message} optionsProps={{ 'Trator': '1', 'Helicóptero': '2', 'Semirreboque': '3', 'Carroceria': '4', 'Caminhão e Ônibus': '5', 'Colheitadeira': '6' }} />
                    </label>
                    <label className="w-full sm:w-[17%] flex flex-col">
                        <button className="w-full bg-[#00AEEF] h-11 rounded-full px-4 py-1 text-white" type="submit">FILTRAR</button>
                    </label>
                </form>
                {
                    (prodsArray.length > 0 && prodsArray[0].total_count > 0) || prodsArray.length > 1 ?
                        <>
                            <section className="w-full min-h-[max-content] h-[max-content] gap-4 flex flex-col sm:flex-row sm:h-[max-content]">

                                <section className={`bg-white w-[100%] rounded-lg md:w-[${user?.is_admin ? 50 : 100}%] border-2 border-solid border-white`}>
                                    <div className="p-7 flex flex-col justify-center items-center w-full font-semibold">
                                        PRODUTOS MAIS PESQUISADOS
                                        <ApexChartsModule prodsArray={prodsArray} />
                                    </div>
                                </section>
                                {
                                    user?.is_admin && clients ?
                                        <section className={`p-2 bg-white w-[100%] rounded-lg md:w-[${user?.is_admin ? 50 : 100}%] border-2 border-solid border-white`}>
                                            <div className="flex w-full justify-center p-7 font-bold">
                                                RANKING DOS CLIENTES
                                            </div>
                                            <div className="w-full px-5 h-[260px] overflow-y-scroll mb-2">
                                                <ul className="w-full">
                                                    {
                                                        clients.map((item: any, index) => {
                                                            const calc = ((item.total_count / biggestCount) * 100);
                                                            if (item.length === 1) {
                                                                return (
                                                                    <div className="text-gray-400 P-5">
                                                                        Ops, sem clientes...
                                                                    </div>
                                                                )
                                                            }
                                                            return (
                                                                <li className='text-white text-sm'>
                                                                    <div className="flex flex-col w-full align-top">
                                                                        <p className="bg-white p-2 text-black w-[100%]">{index + 1}° - {item.company_name}</p>
                                                                        <p className={`p-2 bg-blue-500 h-[max-content] rounded-tr-xl rounded-br-xl text-[.7rem]`} style={{ width: `${calc}%` }}>{item.total_count} Consultas</p>
                                                                    </div>
                                                                </li>
                                                            )
                                                        })
                                                    }

                                                </ul>
                                            </div>
                                            <div className="text-white flex w-full justify-around items-center my-2 border-top">
                                                <div className={`flex mt-3 ${page > 1 ? 'bg-blue-500' : 'bg-gray-300'} p-1 rounded-full`} onClick={() => handlePage(page, false)}>
                                                    <FaChevronLeft size={25} />
                                                </div>
                                                <div className={`flex mt-3 ${page < totalPages ? 'bg-blue-500' : 'bg-gray-300'} p-1 rounded-full`} onClick={() => handlePage(page, true)}>
                                                    <FaChevronRight size={25} />
                                                </div>
                                            </div>
                                        </section> : <></>
                                }
                            </section>
                            <section className="w-full flex flex-row flex-wrap gap-4 sm:h-[max-content] mb-2">
                                {prodsArray.map((item: any, index) => {
                                    const models = item.descriptions.map((model: any) => model.descr.slice(0, 25));
                                    const consults = item.descriptions.map((consults: any) => consults.descr_count);
                                    if (models.length < 1 || consults.length < 1) {
                                        return null;
                                    }
                                    return (
                                        <section
                                            key={index}
                                            className="bg-white h-[max-content] w-full rounded-lg border-2 border-solid border-white"
                                        >
                                            <div className="p-5 font-semibold">
                                                MODELOS POR PRODUTO: <span className="text-orange-400">{item.product.toUpperCase()}</span>
                                            </div>
                                            <ColumnChart colorProp={'#00AEEF'} title="" categories={models} consults={consults} />
                                        </section>
                                    );
                                })}
                            </section>
                        </>
                        : filtered ?
                            <section className="bg-white mb-4 h-[60vh] w-full rounded-lg border-2 border-solid border-white flex justify-center">
                                <div className="text-center sm:text-xl text-gray-300 font-semibold flex items-center justify-center">
                                    <TbFileSad size={70} color={'rgb(209 213 219)'} />
                                    <div className="text-start">
                                        <p>Ops, não há registro dos dados fornecidos...</p>
                                        <p>Tente com outros outros filtros.</p>
                                    </div>
                                </div>
                            </section> : <></>
                }
            </main>
        </Core>
    )
}

export default StatisticsScreen;