import { CircularProgress } from "@mui/material"
import { DragAndDropStyles, ResponsiveTypography } from "./styles"
import BackupIcon from '@mui/icons-material/Backup'
import { RiFolderUploadFill } from "react-icons/ri"

type DragAndDropProps = {
  dragFile: boolean,
  text: string,
  progress?: boolean,
  heightStyled?: string
}

export default function DragAndDrop({ dragFile, text, progress=false, heightStyled='60vh' }: DragAndDropProps): JSX.Element {
  return (
    <DragAndDropStyles heightStyled={heightStyled}>
      {
        progress ?
        !dragFile
          ? <ResponsiveTypography variant="h5">
              {text === 'Arraste sua planilha aqui!' ? <div style={{display: 'flex', alignItems: 'center'}}><BackupIcon /> {text}</div> : <div style={{display: 'flex', alignItems: 'center'}}><RiFolderUploadFill /> {text}</div> }
            </ResponsiveTypography>
          : <></>
          : <CircularProgress />
      }
    </DragAndDropStyles>
  )
}