import { Link } from "react-router-dom"
import useWindowSize from "../../hooks/useWindowSize"
import colors from "../../styles/colors"
import { BlueBarStyles, ResponsiveTypography } from "./styles"

type NewBlueBarProps = {
	path?: string
	label?: string
	value?: object
}

export function NewBlueBar({ path, label, value }: NewBlueBarProps): JSX.Element {
	const { width } = useWindowSize()
	return (
		<>
			{
				path !== undefined
					? <BlueBarStyles>
							<Link to={path} state={{ value: value }}>
								<ResponsiveTypography variant={width > 600 ? "h6" : "body2"} style={{ color: `${colors.IceWhite}` }}>
									{label}
								</ResponsiveTypography>
							</Link>
						</BlueBarStyles>
					: <BlueBarStyles />
			}
		</>
		
	)
}