import Core from "../../Core"
import { useCallback } from 'react'
import { UserProps } from "../types"
import { toast } from "react-toastify"
import * as api from "../../../services/api"
import { Fragment, useEffect, useState } from "react"
import useWindowSize from '../../../hooks/useWindowSize'
import { useNavigate, useParams } from "react-router-dom"
import { DefaultMessage } from "../../../utils/defaultMessages"
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded'
import { Avatar, Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, Divider, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, TextField, Typography } from "@mui/material"
import { MdNoAccounts } from "react-icons/md";

export default function Users(): JSX.Element {
  const { id } = useParams()
  const navigate = useNavigate()
  const { width } = useWindowSize()
  const [data, setData] = useState<UserProps[]>()
  const [dataSearch, setDataSearch] = useState<UserProps[]>()
  const [idUserToDelete, setIdUserToDelete] = useState<string>()
  const [emailUserToDelete, setEmailUserToDelete] = useState<string>()

  const getData = useCallback(async () => {
    await api.get(`user/list/company/?company=${id}`).then((response: any) => {
      setData(response.data.content)
      setDataSearch(response.data.content)
    }).catch((error) => {
      return toast.error(DefaultMessage.ERROR, {
        position: toast.POSITION.TOP_RIGHT
      })
    })
  }, [id])

  function handleOpenDialog(idUser: string, email: string) {
    setIdUserToDelete(idUser)
    setEmailUserToDelete(email)
  }

  const deleteUser = async () => {
    toast.loading(
      "Aguarde...", {
      position: toast.POSITION.TOP_RIGHT,
    }
    )

    await api.destroy(`user/destroy/${idUserToDelete}/`).then((response: any) => {
      toast.dismiss()
      if (response.status === 204) {
        getData()
        toast.success("Usuário excluído com sucesso!", {
          position: toast.POSITION.TOP_RIGHT
        })
      } else {
        toast.error(response.data.content, {
          position: toast.POSITION.TOP_RIGHT
        })
      }
    }).catch((error) => {
      toast.dismiss()
      toast.error(DefaultMessage.ERROR, {
        position: toast.POSITION.TOP_RIGHT
      })
    })
  }

  const DialogComponent = (
    <Dialog
      open={idUserToDelete !== undefined}
      onClose={() => {
        setIdUserToDelete(undefined)
        setEmailUserToDelete(undefined)
      }}
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Typography color="black">
            Tem certeza que deseja excluir o usuário {emailUserToDelete}?
          </Typography>
          <Typography color="red">
            Essa operação não poderá ser desfeita
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={
            () => {
              setIdUserToDelete(undefined)
              setEmailUserToDelete(undefined)
            }
          }
          color="primary"
        >
          NÃO
        </Button>
        <Button
          onClick={
            () => {
              deleteUser()
              setIdUserToDelete(undefined)
            }
          }
        >
          SIM
        </Button>
      </DialogActions>
    </Dialog>
  )

  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value
    search(value)
  }

  function search(value: string) {
    setDataSearch(
      data?.filter(
        (item) => item.first_name.toLowerCase().indexOf(value.toLowerCase()) > -1 ||
          item.last_name.toLowerCase().indexOf(value.toLowerCase()) > -1 ||
          item.email.toLowerCase().indexOf(value.toLowerCase()) > -1
      )
    )
  }

  useEffect(() => {
    getData()
  }, [getData])

  return (
    <Core withNavbar withBlueBar path={`/new-user`} label="Clique aqui para cadastrar um novo usuário" value={{ company_id: id }}>
      {DialogComponent}
      <Grid
        container
        direction="column"
        alignContent="center"
        alignItems="center"
        textAlign="center"
        paddingBottom={10}
        paddingX={width > 600 ? 10 : 2}
        gap={2}
      >
        <Chip label={
          data && data.length > 0
            ? `Usuários da empresa ${data[0].company_name}`
            : "Usuários cadastrados"
        }
        />
        <TextField
          type="text"
          placeholder="Pesquisar"
          fullWidth
          onChange={handleChangeSearch}
        />
        <List sx={{ width: '90%', bgcolor: 'background.paper' }}>
          {
            dataSearch?.map(
              (item, index) =>
                <div key={`file-item-${index}`}>
                  <ListItem
                    alignItems="flex-start"
                    secondaryAction={
                      <Fragment>
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() => { handleOpenDialog(item.id, item.email) }}
                        >
                          <DeleteRoundedIcon />
                        </IconButton>
                      </Fragment>
                    }
                  >
                    <ListItemAvatar>
                      {
                        !item.blocked ? <Avatar /> :  <MdNoAccounts size={48} style={{position: 'relative', left: '-5px', textAlign: 'left'}} color='#bdbdbd' />
                      }
                    </ListItemAvatar>
                    <ListItemButton
                      onClick={
                        () => {
                          navigate(
                            `/user/${item.id}`,
                          )
                        }
                      }
                    >
                      <ListItemText
                        primary={`${item.first_name} ${item.last_name}`}
                        secondary={
                          <Fragment>
                            <i style={{ color: "black" }}>{item.email}</i>
                            <br />
                            <i style={{ color: "black" }}>Empresa: {item.company_name}</i>
                            <br/>
                            {
                              item.is_representative 
                              ?
                                <i style={{ color: "black", fontWeight: '600' }}>Responsável pela empresa.</i>
                              :
                                null
                            }
                          </Fragment>
                        }
                      />
                    </ListItemButton>
                  </ListItem>
                  <Divider variant="inset" component="li" />
                </div>
            )
          }
        </List>
      </Grid>
    </Core>
  )
}
