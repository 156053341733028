import { Link } from "react-router-dom"
import { PlateChassiStyles } from "./plateChassiStyles"
import React, { PureComponent } from "react"

interface InfoPriceProps {
  reference: React.RefObject<HTMLInputElement>
  type: string
  year: string
  hash: string
  model: string
  value: number
  mounter: string
  date_time: string
  somos_code: string
  updated_at: string
  codigo_fipe: string
}

const message: {[key: string]: string} = {
  "1": "*O preço do referido Trator não leva em consideração as condições dos pneus, total de horas trabalhadas, tipos de equipamentos adicionais, condições de pintura e lataria, histórico de acidente/incidente, histórico de manutenção e comissão de vendas.",
  "2": "*O preço da aeronave está sujeita a variação cambial do Dólar, aos aviônicos e equipamentos adicionais, horas disponíveis ou horas totais de voo, a Revisão de 12 anos, condições do interior e exterior, histórico de acidente/incidente, histórico de manutenção, documentação da Aeronave e comissão de vendas.",
  "3": "*O preço do referido implemento é sem pneus, não leva em consideração os tipos de acessórios adicionais, tipo de suspensão, condições de pintura e lataria, histórico de acidente/incidente, histórico de manutenção e comissão de vendas.",
  "4": "*Preço da referida carroceria sobre chassi , não leva em consideração os tipos de acessórios adicionais,  condições de pintura e lataria, histórico de acidente/incidente, histórico de manutenção e comissão de vendas.”",
  "5": "",
  "6": "*O preço do referido implemento é sem pneus, não leva em consideração os tipos de acessórios adicionais, tipo de suspensão, condições de pintura e lataria, histórico de acidente/incidente, histórico de manutenção e comissão de vendas."
}

const somosLogo = require("../../images/horizontalLogo.png")

export default class InfoPrice2 extends PureComponent<InfoPriceProps> {
  render(): React.ReactNode {
    const { 
      reference,
      type,
      somos_code,
      mounter,
      model,
      year,
      updated_at,
      date_time,
      value,
      codigo_fipe,
      hash
    } = this.props

    return (
      <PlateChassiStyles id="report" ref={reference} style={{display: 'flex', alignItems: 'center'}}>
        <div id="report-logo">
          <Link to="https://tabelasomos.com.br">
            <img src={somosLogo} width={270} alt="Logo" />
          </Link>
        </div>
        <strong id="info-message-and-hash" className="hash">Hash de autenticidade: {hash}</strong>
        <table id="report-body-card">
          <tbody>
            <tr>
              <td className="no-border">Código {type === '5' ? 'FIPE' : 'Somos'}</td>
              <td>{somos_code || codigo_fipe}</td>
            </tr>
            <tr>
              <td className="no-border">Fabricante</td>
              <td>{mounter}</td>
            </tr>
            <tr>
              <td className="no-border">Modelo</td>
              <td>{model}</td>
            </tr>
            <tr>
              <td className="no-border">Ano</td>
              <td>{year}</td>
            </tr>
            <tr>
              <td className="no-border">Atualizado em</td>
              <td>{updated_at}</td>
            </tr>
            <tr>
              <td className="no-border">Data da consulta</td>
              <td>{date_time}</td>
            </tr>
            <tr className="price">
              <td className="no-border">Preço médio</td>
              <td>{type === '5' ? `${String(value).replace(',00', '')}` : `R$ ${value.toLocaleString('pt-BR', { style: 'decimal' })}*`}</td>
            </tr>
          </tbody>
        </table>
        <i id="info-message-and-hash" style={window.innerWidth < 600 ? {width: '95vw'} : {width: "50vw"}}>{message[type]}</i>
        <p id="report-link-site">
          <Link to="https://tabelasomos.com.br">www.<strong>TABELASOMOS</strong>.com.br</Link>
        </p>
      </PlateChassiStyles>
    )
  }
}