import * as api from "../../services/api"
import NavBar from "../../components/NavBar"
import { useAuth } from "../../hooks/useAuth"
import { cookies } from "../../hooks/useCookies"
import { LightMode } from "../../styles/lightMode"
import { ToastContainer, toast } from "react-toastify"
import { NewBlueBar } from "../../components/NewBlueBar"
import React, { createContext, useMemo, useState, useEffect, useCallback } from "react"
import { createTheme, Grid, PaletteMode, ThemeProvider } from "@mui/material"

type CoreProps = {
	children: React.ReactNode
	withBlueBar?: boolean
	withNavbar?: boolean
	path?: string
	label?: string
	value?: object
}

const getDesignTokens = (mode: PaletteMode) => ({
	palette: mode === 'light' ? LightMode : LightMode
})

const ColorModeContext = createContext({ toggleColorMode: () => { } })

export default function Core({ children, withBlueBar, withNavbar, path, label, value }: CoreProps): JSX.Element {
	// Configuração para aplicar paleta de cores do MUI
	const [mode, setMode] = useState<PaletteMode>(cookies.get("@Somos:themeMode") ?? "light")
	const { user } = useAuth();
	const [downloading, setDownloading] = useState(false);
	const [firstTimeProcess, setFirstTimeProcess] = useState(true);
	const [toastDisplayed, setToastDisplayed] = useState(false);

	const colorMode = useMemo(
		() => ({
			toggleColorMode: () => {
				setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'))
				cookies.set('@Somos:themeMode', mode === 'light' ? 'dark' : 'light', { path: '/', sameSite: 'lax' })
				window.location.reload()
			},
		}),
		[mode]
	)
	const theme = useMemo(() => createTheme(getDesignTokens(mode)), [mode])

	// Polling function to check the status of the task
	const checkExcelTaskStatus = useCallback(() => {
		const taskId = localStorage.getItem('excel_task_id');
		if (!taskId || downloading) {
			return;
		}

		setDownloading(true);

		let downloadUrl = `${api.baseURL}/table/precificador/excel/download/${taskId}`;
		downloadUrl = downloadUrl.slice(0, -1) + "";

		const options = {
			method: 'GET',
			headers: {
				Authorization: `Bearer ${user?.access}`
			}
		};

		fetch(downloadUrl, options)
			.then(response => {
				if (response.status === 202 && firstTimeProcess && !toastDisplayed) {
					// Task is still processing
					toast.info("Gerando dados para o arquivo semitrailer.xlsx...", {
						position: toast.POSITION.TOP_RIGHT,
						autoClose: 2000
					});
					setFirstTimeProcess(false);
					setToastDisplayed(true);
				} else if (response.status === 200) {
					// Task is complete and the file is ready
					response.blob().then(blob => {
						const url = window.URL.createObjectURL(new Blob([blob]));
						const link = document.createElement('a');
						link.href = url;
						link.setAttribute('download', 'semitrailers.xlsx');
						document.body.appendChild(link);
						link.click();
						link.parentNode?.removeChild(link);

						// Clear the task ID from local storage
						localStorage.removeItem('excel_task_id');

						toast.success("O arquivo semitrailers.xlsx está pronto para download.", {
							position: toast.POSITION.TOP_RIGHT
						});
					});
					setToastDisplayed(false);
				} else if (response.status === 400) {
					// Task failed
					localStorage.removeItem('excel_task_id');
					toast.error("Erro ao gerar o arquivo semitrailers.xlsx", {
						position: toast.POSITION.TOP_RIGHT
					});
					setToastDisplayed(false);
				}
			})
			.catch(err => {
				console.error(err);
				localStorage.removeItem('excel_task_id');
				toast.error("Erro ao verificar o status do arquivo semitrailers.xlsx", {
					position: toast.POSITION.TOP_RIGHT
				});
				setToastDisplayed(false);
			})
			.finally(() => {
				setDownloading(false);
				setFirstTimeProcess(true);
			});
	}, []);

	// useEffect(() => {
	// 	const intervalId = setInterval(checkExcelTaskStatus, 15000);
	// 	return () => clearInterval(intervalId); // Cleanup interval on unmount
	// }, [downloading, checkExcelTaskStatus]);

	return (
		<ColorModeContext.Provider value={colorMode}>
			<ThemeProvider theme={theme}>
				{/* {
					withNavbar
						? <NavBar />
						: <></>
				} */}
				{
					withBlueBar
						? <NewBlueBar path={path} label={label} value={value} />
						: <></>
				}
				<ToastContainer />
				<Grid
					marginTop={withNavbar ? 20 : 0}
				>
					{children}
				</Grid>
			</ThemeProvider>
		</ColorModeContext.Provider>
	)
}