import useWindowSize from "../../hooks/useWindowSize"
import { CardSomosStyle, CardSomosMobileStyle } from "./styles"

interface CardSomosProps {
  children: React.ReactNode
}

export default function CardSomos({ children }: CardSomosProps): JSX.Element {
  const { width } = useWindowSize()

  return width > 600
    ? <CardSomosStyle>
        {children}
      </CardSomosStyle>
    : <CardSomosMobileStyle>
        {children}
      </CardSomosMobileStyle>
}