import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';

interface Prod {
  product: string;
  total_count: number; // Atualizado para total_count
}

interface ApexChartModuleProps {
  prodsArray: Prod[];
}

const ApexChartModule: React.FC<ApexChartModuleProps> = ({ prodsArray }) => {
  const [series, setSeries] = useState<number[]>([]);
  const [options, setOptions] = useState<any>({
    chart: {
      width: 500,
      type: 'pie' as 'pie',
    },
    labels: [] as string[],
    colors: ['#0000aa', '#4169e1', '#0092ff', '#00AEFF', '#87ceeb', '#00678d'],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 350,
            height: 350,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
    dataLabels: {
      enabled: true,
      formatter: (val: number) => `${val.toFixed(1)}%`, // Valor padrão
      style: {
        fontSize: '14px',
        fontWeight: 'bold',
        colors: ['white'],
      },
    },
  });

  useEffect(() => {
    if (prodsArray && prodsArray.length > 0) {
      const filteredProducts = prodsArray.map((item) => item.product);
      const filteredSeries = prodsArray.map((item) => item.total_count);
      setSeries(filteredSeries);
      setOptions((prevOptions: any) => ({
        ...prevOptions,
        labels: filteredProducts,
      }));
    }
  }, [prodsArray]);

  useEffect(() => {
    if (series.length > 0) {
      setOptions((prevOptions: any) => ({
        ...prevOptions,
        dataLabels: {
          ...prevOptions.dataLabels,
          formatter: (val: number, { seriesIndex }: { seriesIndex: number }) => {
            const realValue = series[seriesIndex];
            return realValue !== undefined
              ? `${realValue} Consultas - (${val.toFixed(1)}%)`
              : `${val.toFixed(1)}%`;
          },
        },
      }));
    }
  }, [series]);

  if (series.length === 0) {
    return <div className="text-gray-400 p-5">Loading chart...</div>;
  }

  return (
    <div className="mt-5">
      <div id="chart">
        <ReactApexChart options={options} series={series} type="pie" width={450} />
      </div>
      <div id="html-dist"></div>
    </div>
  );
};

export default ApexChartModule;