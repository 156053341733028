import Core from "../../Core"
import { toast } from "react-toastify"
import React, { useState } from "react"
//@ts-ignore
import styles from './styles.module.css'
import { GiPadlock } from "react-icons/gi"
import colors from "../../../styles/colors"
import * as api from  '../../../services/api'
import { useNavigate } from "react-router-dom"
import { cookies } from '../../../hooks/useCookies'
import useWindowSize from "../../../hooks/useWindowSize"
import { Button, Grid, Typography } from "@mui/material"
import InputSignUpMemorized from "../../../components/InputSignUp"


export default function NewPassword(): JSX.Element {
  const { width } = useWindowSize()
  const navigate = useNavigate()
  const [disabledButton, setDisabledButton] = useState<boolean>(false)
  const [formData, setFormData] = useState({
    token: cookies.get('@Somos:PasswordToken'), 
    password: "", 
    confirm_password:  ""
  })

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const handleKey = (event: { key: string }) => {
    if (event.key === "Enter") {
      updatePassword()
    }
  }

  const somosLogo = require("../../../images/horizontalLogo.png")

  async function updatePassword() {
    setDisabledButton(true)
    toast.loading("Aguarde...", {
      position: toast.POSITION.TOP_RIGHT,
    })
    if (formData.password !== formData.confirm_password) {
      setDisabledButton(false)
      toast.dismiss()
      return toast.error("As senhas informadas são diferentes!", {
        position: toast.POSITION.TOP_RIGHT
      })
    }
    
    await api.post(
      `user/password_reset/confirm/`, formData
    ).then((response: any) => {
      if (response.status === 200) {
        toast.dismiss()
        cookies.remove('@Somos:PasswordToken')
        cookies.set('@Somos:toastFirstLogin', true, { path: '/', sameSite: 'lax' })
        toast.success("Senha atualizada com sucesso, faça login novamente! Redirecionando, aguarde...")
        setTimeout(() => {
          navigate("/")
        }, 2000)
      } else {
        setDisabledButton(false)
        toast.dismiss()
        toast.error("Verifique se sua senha respeita a regra informada ou se o token informado ainda é válido!", {
          position: toast.POSITION.TOP_RIGHT
        })
      }
    }).catch((error) => {
      setDisabledButton(false)
      toast.dismiss()
      toast.error("Verifique se sua senha respeita a regra informada!", {
        position: toast.POSITION.TOP_RIGHT
      })
    })
  }

  const iconsProps = {
    color: "white",
    size: width <= 768 ? 18 : 28
  }

  return (
    <Core>
      <Grid
        container
        height="100vh"
        alignContent="center"
        alignItems="center"
        textAlign="center"
        justifyContent="center"
        justifyItems="center"
        sx={{ backgroundColor: `${colors.DarkerGray}` }}
      >
        {width > 900 ? <> </> : <div className={styles.imageBackMobile} style={{ height: '100vh', width: '100%', position: 'fixed', zIndex: '0' }} />}
        {
          width > 900
            ? <Grid
              height={'100%'}
              width={'50%'}
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
              item
            >
              <div style={{ height: '100%', width: '100%' }}>
                <div className={styles.imageBack} style={{ width: '-webkit-fill-available', height: '100%', top: '0', zIndex: '0', position: 'fixed' }} />
                {/* <div style={{position: 'absolute', height: '100%', width: '-webkit-fill-available', top: '0', zIndex: '1', backgroundColor: '#000000d4' }}/> */}
              </div>
              <img src={somosLogo} width={'100%'} height="auto" alt="Logo" style={{ zIndex: '2', position: "fixed", width: '40%' }} />
            </Grid>
            : <Grid
              container
              height={'max-content'}
              display={'flex'}
              justifyContent={'center'}
              width={width > 900 ? '50%' : '80%'}
              flexDirection="row"
              alignItems="center"
              marginBottom={2}
            >
              <img src={somosLogo} width={'100%'} height="auto" alt="Logo" style={{ zIndex: '2', marginTop: width <= 901 ? "50px" : "0" }} />
            </Grid>
        }
          <form style={{width: width >= 900 ? '50%' : '100%', zIndex: 3, alignItems: 'center', display: 'flex', justifyContent: 'center', gap: '30px', flexDirection: 'column'}}>
              <Typography variant={width > 720 ? 'h4' : 'h6'} color="primary">
                <strong>DEFINA SUA NOVA SENHA</strong>
              </Typography>
              <div>
                <Typography variant="body1" color="secondary">
                  <strong>Defina uma nova senha para continuar</strong>
                </Typography>
                <Typography variant="body1" color="secondary">
                  Sua senha deve conter no mínimo 8 caracteres, sendo:
                </Typography>
                <Typography className={styles.zeroPadding} variant="body1" color="secondary" sx={{padding: '0'}} style={{padding: '0'}} padding={0}>
                  <ul>
                    <li>1 Caractére Especial</li>
                    <li>1 Caractére Numérico</li>
                    <li>1 Caractére Maiúsculo</li>
                  </ul>
                </Typography>
              </div>
              <div style={{gap: '20px', display: 'flex', flexDirection: 'column', width: '80%'}}>
                <InputSignUpMemorized
                  icon={<GiPadlock {...iconsProps} />}
                  onKeyUp={handleKey}
                  type="password"
                  name="password"
                  className="clientInput"
                  placeholder="Informe uma nova senha"
                  fullWidth
                  value={""}
                  onChange={handleChange}
                  style={undefined}
                />
                <InputSignUpMemorized
                  icon={<GiPadlock {...iconsProps} />}
                  className="clientInput"
                  type="password"
                  name="confirm_password"
                  placeholder="Repita a nova senha"
                  fullWidth
                  value={""}
                  onKeyUp={handleKey}
                  onChange={handleChange}
                  style={undefined}
                />
              </div>
              <div style={{width: '80%'}}>
                <Button
                  disabled={disabledButton}
                  className={`btn ${styles.buttonSend}`}
                  variant="contained"
                  size="large"
                  color="secondary"
                  onClick={updatePassword}
                >
                  Enviar
                </Button>
              </div>
          </form>
      </Grid>
    </Core>
  )
}
