import { Doc } from '../pages/Doc'
import Users from '../pages/User/Users'
import Histories from '../pages/Histories'
import Dashboard from '../pages/Dashboard'
import MyAccount from '../pages/User/MyAccount'
import MyCompany from '../pages/Company/MyCompany'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import CreateOrUpdateUser from '../pages/User/CreateOrUpdateUser'
import StatisticsScreen from '../pages/StatisticsScreen'
import SearchEngines from '../pages/SearchEngines'
import NavBar from '../components/NavBar'

export default function RepresentativeRoutes(): JSX.Element {

  return (
    <BrowserRouter>
    <NavBar />
      <Routes>
        <Route path='*' element={<Dashboard />}/>
        <Route path='/' element={<Dashboard />}/>
        <Route path='/company/:id' element={<MyCompany />}/>
        <Route path='/users/company/:id' element={<Users />}/>
        <Route path='/user/:id' element={<CreateOrUpdateUser />}/>
        <Route path='/new-user' element={<CreateOrUpdateUser />}/>
        <Route path='/histories' element={<Histories />} />
        <Route path='/my-account/:id' element={<MyAccount />}/>
        <Route path='/doc' element={<Doc />} />
        <Route path='/statistics' element={<StatisticsScreen />} />
        <Route path='/search-engines' element={<SearchEngines />} />
      </Routes>
    </BrowserRouter>
  )
}